
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
	name: "EventParticipationScoreRenderer",
	props: {
		params: {
			type: Object as PropType<{ value: number | string }>,
			required: true,
		},
	},
	methods: {},
	computed: {},
});
