
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { adComponentMixin, courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";

import EventParticipationPreview from "@/components/student/EventParticipationPreview.vue";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import Card from "@/components/ui/Card.vue";
import {
	Event,
	EventParticipation,
	EventType,
	getBlankEventTemplate,
	getBlankPractice,
} from "@/models";
import Dialog from "@/components/ui/Dialog.vue";
import { getTranslatedString as _ } from "@/i18n";
import { sum } from "lodash";
import { demoStudentTourSteps, MAX_PRACTICE_EXERCISE_COUNT, tourOptions } from "@/const";
import Btn from "@/components/ui/Btn.vue";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import Spinner from "@/components/ui/Spinner.vue";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import { EventParticipationSearchFilter } from "@/api";
import { isDemoMode, setErrorNotification } from "@/utils";
import { logAnalyticsEvent } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import PracticeTemplateEditorNew from "../../../components/student/PracticeTemplateEditorNew.vue";
import { useGoogleIntegrationsStore } from "../../../integrations/stores/googleIntegrationsStore";

const DEMO_TOUR_KEY = "demo_student_tour_taken";
const SCOPES_REQUIRED_TO_CREATE_PRACTICE = true;

export default defineComponent({
	components: {
		EventParticipationPreview,
		SkeletonCard,
		Card,
		Dialog,
		Btn,
		VueEternalLoading,
		Spinner,
		PracticeTemplateEditorNew,
	},
	name: "PracticeList",
	mixins: [courseIdMixin, loadingMixin, adComponentMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getTags({
				courseId: this.courseId,
				includeExerciseCount: true,
			});
			// await this.mainStore.getCourse({ courseId: this.courseId });
			await this.mainStore.getCourseEventParticipations({
				courseId: this.courseId,
				fromFirstPage: true,
				filter: {
					event_type: EventType.SELF_SERVICE_PRACTICE,
				} as EventParticipationSearchFilter,
			});
			if (isDemoMode() && !(DEMO_TOUR_KEY in localStorage)) {
				setTimeout(() => ((this as any).$tours["demoStudentTour"] as any).start(), 50);
				localStorage.setItem(DEMO_TOUR_KEY, "true");
			}
		});
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
			isEditingRule: false,
			MAX_PRACTICE_EXERCISE_COUNT,
			showNotRecent: true,
			showBookmarkedOnly: false,
			loadingParticipations: new Set<string>(),
			tourOptions,
			demoStudentTourSteps,
			showPracticeEditorDialog: false,
			draftEventTemplate: getBlankEventTemplate(),
			practiceEditorDialogLoading: false,
		};
	},
	methods: {
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getCourseEventParticipations({
					courseId: this.courseId,
					fromFirstPage: false,
					filter: {
						event_type: EventType.SELF_SERVICE_PRACTICE,
					} as EventParticipationSearchFilter,
				});

				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch {
				error();
			}
		},
		onCreatePractice() {
			/**
			 * Open practice editor dialog and lets user define the practice template
			 */
			logAnalyticsEvent("createPractice", { courseId: this.courseId });
			if (this.loading) {
				return;
			}
			// if student hasn't granted scopes yet, deny practice creation
			if (
				this.googleIntegrationStore.studentScopesBannerVisible &&
				SCOPES_REQUIRED_TO_CREATE_PRACTICE
			) {
				this.googleIntegrationStore.studentScopesBannerShaking = true;
				return;
			}
			if (this.currentCourse.public_exercises_count === 0) {
				// TODO if you use this.setErrorNotification, it's undefined - investigate
				setErrorNotification(_("student_course_dashboard.no_public_exercises"), true);
				return;
			}
			this.showPracticeEditorDialog = true;
		},
		async onBeginPractice() {
			/**
			 * Create practice event using defined template and redirect to
			 * practice participation page
			 */
			logAnalyticsEvent("beginPractice", { courseId: this.courseId });

			const eventPayload = {
				...getBlankPractice(),
				template: this.draftEventTemplate,
			};

			try {
				this.practiceEditorDialogLoading = true;

				// create practice event with draftEventTemplate
				const newPracticeEvent = await this.mainStore.createEvent({
					courseId: this.courseId,
					event: eventPayload,
				});

				this.$router.push({
					name: "PracticeParticipationPage",
					params: {
						examId: newPracticeEvent.id,
					},
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.practiceEditorDialogLoading = false;
			}
		},
		onCancelPracticeEditorDialog() {
			this.showPracticeEditorDialog = false;
		},
		async onBookmark(participation: EventParticipation) {
			try {
				logAnalyticsEvent("bookmarkedPractice", { courseId: this.courseId });
				this.loadingParticipations.add(participation.id);
				await this.mainStore.partialUpdateEventParticipation({
					courseId: this.courseId,
					// TODO assumes participation contains Event
					eventId: participation.event.id,
					participationId: participation.id,
					changes: {
						bookmarked: !(participation.bookmarked ?? false),
					},
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingParticipations.delete(participation.id);
			}
		},
	},
	computed: {
		...mapStores(useMainStore, useGoogleIntegrationsStore),
		filteredPracticeParticipations() {
			return this.mainStore.practiceParticipations.filter(
				(p, i) =>
					(this.showNotRecent || i < 3 || (p.bookmarked && this.showBookmarkedOnly)) &&
					(!this.showBookmarkedOnly || p.bookmarked),
			);
		},
		// isResumingUnstartedPractice(): boolean {
		// 	return (
		// 		this.mainStore.editingEvent?.id ===
		// 		this.currentCourse.unstarted_practice_events?.[0]?.id
		// 	);
		// },
		// totalRuleAmount(): number {
		// 	if (!this.mainStore.editingEvent) {
		// 		return 0;
		// 	}
		// 	return sum(
		// 		(this.mainStore.editingEvent as Event).template?.rules.map(r =>
		// 			parseInt(String(r.amount)),
		// 		),
		// 	);
		// },
	},
});
