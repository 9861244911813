
import { HelpCenterArticle } from "@/helpCenter";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import { logAnalyticsEvent } from "@/utils";
export default defineComponent({
	name: "HelpCenterArticleFull",
	props: {
		article: {
			type: Object as PropType<HelpCenterArticle>,
			required: true,
		},
		allowGoBack: {
			type: Boolean,
			default: true,
		},
	},
	created() {
		logAnalyticsEvent("openedHelpCenterArticle", { articleId: this.article.id });
	},
	methods: {},
	computed: {},
	components: { Btn },
});
