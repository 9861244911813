
const BORDER_SIZE = 4;
import { texMixin } from "@/mixins";
import { v4 as uuid4 } from "uuid";
import { defineComponent } from "@vue/runtime-core";

import { quillEditor } from "vue3-quill";

import Quill from "quill";
const ImageResize = require("quill-image-resize").default;
const ImageDrop = require("quill-image-drop-module").ImageDrop;
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

import Btn from "./Btn.vue";
import { containsLatex, stripHtmlFromLaTexBlocks } from "@/utils";
export default defineComponent({
	name: "TextEditor",
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		tall: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
		forceBaseEditor: {
			type: Boolean,
			default: false,
		},
		showPreviewButton: {
			type: Boolean,
			default: true,
		},
		baseEditorRows: {
			type: Number,
			default: 8,
		},
	},
	mixins: [texMixin],
	components: {
		quillEditor,
		Btn,
	},
	watch: {
		showPreview(newVal) {
			if (newVal) {
				this.triggerTexRender();
			}
		},
		modelValue() {
			if (this.showPreview) {
				if (this.triggerTexHandle !== null) {
					clearTimeout(this.triggerTexHandle);
				}
				this.triggerTexHandle = setTimeout(() => this.triggerTexRender(), 100);
			}
		},
		showBaseEditor(newVal) {
			this.$nextTick(this.addCustomButtons);
		},
	},
	mounted() {
		// prevent auto-focusing of quill editor
		this.$nextTick(() => (this.internalDisabled = false));
		this.addCustomButtons();
	},
	data() {
		return {
			showBaseEditor: false,
			instance: null as any,
			//content: "",
			internalDisabled: true,
			showPreview: false,
			resizablePanelId: uuid4(),
			previewPanelWidth: 50,
			pos: 0,
			triggerTexHandle: null as number | null,
			id: "a" + uuid4(), // https://github.com/testing-library/dom-testing-library/issues/168,
		};
	},
	methods: {
		handleDragging(e: any) {
			const percentage = ((e.pageX - 51) / window.innerWidth) * 100;
			if (percentage >= 30 && percentage <= 70) {
				this.previewPanelWidth = percentage;
			}
		},
		addCustomButtons() {
			// add custom LaTeX button
			const latexButton = document.querySelector("#" + this.id + " .ql-latex");
			if (latexButton) {
				// when clicked, the LaTeX button inserts two $'s and positions the cursor between them
				latexButton.addEventListener("click", () => {
					const selection = this.instance.getSelection(true);
					this.instance.insertText(selection.index, "$$");
					this.instance.setSelection(selection.index + 1, 0);
				});
				latexButton.innerHTML = document.getElementById(`${this.id}latex-button-src`)
					?.innerHTML as string;
			}
			// add custom html button
			const htmlButton = document.querySelector("#" + this.id + " .ql-html");
			if (htmlButton) {
				// when clicked, the html button toggles between full & base editor
				htmlButton.addEventListener("click", () => {
					this.toggleBaseEditor();
				});
				htmlButton.innerHTML = document.getElementById(`${this.id}html-button-src`)
					?.innerHTML as string;
			}
		},
		stripHtmlFromLaTexBlocks,
		startDragging() {
			document.addEventListener("mousemove", this.handleDragging);
		},
		endDragging() {
			document.removeEventListener("mousemove", this.handleDragging);
		},
		toggleBaseEditor() {
			this.showBaseEditor = !this.showBaseEditor;
		},
		onEditorChange({ quill, html, text }: unknown) {
			this.$emit("update:modelValue", html);
		},
		onEditorReady(quill: any) {
			this.$emit("ready", quill);

			quill.on("selection-change", this.onSelectionChange);
			quill.root.addEventListener("blur", () => this.$emit("blur"));
			this.instance = quill;
		},
		onSelectionChange(event: any) {
			if (event) {
				this.$emit("selectionChange", {
					fullText: this.instance.getText(),
					text: this.instance.getText(event.index - 10, event.length + 15),
					range: event,
				});
			}
		},
	},
	computed: {
		previewPanelStyle() {
			if (!this.showPreview) {
				return {};
			}
			return { width: this.previewPanelWidth + "%" };
		},
		dividerStyle() {
			return { left: this.previewPanelWidth + "%" };
		},
		containsLatex() {
			return containsLatex(this.modelValue);
		},
		editorOptions() {
			return {
				theme: "snow",
				placeholder: this.placeholder,
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike"],
						["code-block"],
						//[{ header: 1 }, { header: 2 }],
						[{ list: "ordered" }, { list: "bullet" }],
						//[{ script: 'sub' }, { script: 'super' }],
						//[{ indent: '-1' }, { indent: '+1' }],
						//[{ direction: 'rtl' }],
						// [{ size: ['small', false, 'large', 'huge'] }],
						// [{ header: [1, 2, 3, 4, 5, 6, false] }],
						// [{ color: [] }, { background: [] }],
						// [{ font: [] }],
						// [{ align: [] }],
						["image"], // "video"
						["clean"],
						["latex"],
						["html"],
					],
					imageResize: {
						modules: ["Resize", "DisplaySize"],
					},
					imageDrop: true,
					magicUrl: true,
				},
			};
		},
	},
});
