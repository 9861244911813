
import { EventParticipation } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as assessmentStateIcons } from "@/assets/assessmentStateIcons";
import Btn from "../ui/Btn.vue";
import { IHeaderParams } from "ag-grid-community";

export default defineComponent({
	name: "CourseInsightsExamHeaderRenderer",
	props: {
		params: {
			type: Object as PropType<IHeaderParams>,
			required: true,
		},
	},
	data() {
		return {};
	},
	methods: {},
	computed: {
		examId() {
			return this.params.column.getColId().split("_")[1];
		},
	},
	components: {},
});
