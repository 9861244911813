
import { EventParticipationSlot } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
export default defineComponent({
	name: "EventParticipationSlotScoreRenderer",
	props: {
		params: {
			type: Object as PropType<{
				value: Pick<EventParticipationSlot, "score" | "execution_results">;
			}>,
			required: true,
		},
	},
	methods: {},
	computed: {
		hasNullishScore() {
			return (
				this.params.value.score === null || typeof this.params.value.score === "undefined"
			);
		},
	},
	components: { Btn },
});
