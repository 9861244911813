
import GoogleScopeChecker from "@/integrations/components/GoogleScopeChecker.vue";
import { blockingDialogMixin, courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent, PropType } from "@vue/runtime-core";
import { CLASSROOM_TEACHER_SCOPES } from "@/integrations/classroom/const";
import Toggle from "@/components/ui/Toggle.vue";
import GoogleClassroomCourseItem from "@/integrations/classroom/components/GoogleClassroomCourseItem.vue";
import { mapStores } from "pinia";
import { useGoogleIntegrationsStore } from "@/integrations/stores/googleIntegrationsStore";
import { GoogleClassroomCourseData } from "@/integrations/classroom/interfaces";
import { setErrorNotification } from "@/utils";
import Dialog from "@/components/ui/Dialog.vue";
import Spinner from "@/components/ui/Spinner.vue";
import Btn from "@/components/ui/Btn.vue";
import { useMetaStore } from "@/stores/metaStore";
import ArticleHandle from "@/components/shared/HelpCenter/ArticleHandle.vue";
import TextInput from "@/components/ui/TextInput.vue";
export default defineComponent({
	name: "GoogleClassroomIntegrationSettings",
	props: {},
	mixins: [courseIdMixin, blockingDialogMixin, loadingMixin],
	async created() {
		console.log(this.googleIntegrationStore.createCourseTwin);
		await this.withFirstLoading(
			async () => await this.googleIntegrationStore.getCourseTwin(this.courseId),
		);
	},
	watch: {
		showDisableIntegrationDialog(newVal) {
			if (!newVal) {
				// reset confirm text when dialog gets closed
				this.disableConfirmText = "";
			}
		},
	},
	data() {
		return {
			scopesVerified: false,
			classroomScopes: CLASSROOM_TEACHER_SCOPES,
			selectedClassroomCourseId: null as string | null,
			fetchingClassroomCourses: false,
			creatingCourseTwin: false,
			disablingIntegration: false,
			classroomCourses: [] as GoogleClassroomCourseData[],
			errorWhileFetchingClassroomCourses: false,
			showDisableIntegrationDialog: false,
			disableConfirmText: "",
		};
	},
	methods: {
		async onIntegrationEnable() {
			this.fetchClassroomCourses();
			const choice = await this.getBlockingBinaryDialogChoice();
			if (!choice) {
				this.selectedClassroomCourseId = null;
				this.showBlockingDialog = false;
				return;
			}
			try {
				this.creatingCourseTwin = true;
				await this.googleIntegrationStore.createCourseTwin(
					this.courseId,
					this.selectedClassroomCourseId as string,
				);
				this.metaStore.showSuccessFeedback();
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.creatingCourseTwin = false;
				this.showBlockingDialog = false;
			}
		},
		async fetchClassroomCourses() {
			this.classroomCourses = [];
			this.errorWhileFetchingClassroomCourses = false;
			this.fetchingClassroomCourses = true;
			try {
				this.classroomCourses =
					await this.googleIntegrationStore.getGoogleClassroomCoursesTaughtByCurrentUser();
			} catch (e) {
				this.errorWhileFetchingClassroomCourses = true;
			} finally {
				this.fetchingClassroomCourses = false;
			}
		},
		async onIntegrationDisable() {
			try {
				await this.googleIntegrationStore.partialUpdateCourseTwin(this.courseId, {
					enabled: false,
				});
				this.showDisableIntegrationDialog = false;
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.disablingIntegration = false;
			}
		},
	},
	computed: {
		...mapStores(useGoogleIntegrationsStore, useMetaStore),
		integrationEnabled: {
			get() {
				return this.googleIntegrationStore.courseTwins[this.courseId]?.enabled ?? false;
			},
			async set(val: boolean) {
				if (val) {
					await this.onIntegrationEnable();
				} else {
					this.showDisableIntegrationDialog = true;
				}
			},
		},
		twinCourse() {
			return this.googleIntegrationStore.courseTwins[this.courseId];
		},
		disableConfirmCheckSuccess() {
			const normalize = (text: string) => text.trim().toLocaleLowerCase();
			return normalize(this.currentCourse.name) === normalize(this.disableConfirmText);
		},
	},
	components: {
		GoogleScopeChecker,
		Toggle,
		Dialog,
		Spinner,
		GoogleClassroomCourseItem,
		Btn,
		ArticleHandle,
		TextInput,
	},
});
