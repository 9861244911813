
import { Event, EventState, EventTimeLimitRule, EventType } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Card from "../ui/Card.vue";
import Btn from "../ui/Btn.vue";
import { courseIdMixin } from "@/mixins";
import ParticipationThumbnail from "./ParticipationThumbnail.vue";
import CopyToClipboard from "../ui/CopyToClipboard.vue";
import { getExamPermalink } from "../../utils";
import Tag from "../ui/Tag.vue";

export default defineComponent({
	name: "EventParticipationPreview",
	props: {
		event: {
			type: Object as PropType<Event>,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin],
	data() {
		return {
			EventType,
			EventTimeLimitRule,
		};
	},
	computed: {
		isParticipable(): boolean {
			return this.event.state === EventState.OPEN;
		},
		showThumbnail(): boolean {
			return false; //this.participation.event.event_type === EventType.SELF_SERVICE_PRACTICE;
		},
		// TODO make this a utility to avoid duplication with Countdown.vue
		formattedTime(): {
			hours: string;
			minutes: string;
			seconds: string;
		} {
			const hours = Math.floor((this.event.time_limit_seconds ?? 0) / 3600);
			const minutes = Math.floor(
				((this.event.time_limit_seconds ?? 0) - hours * 3600) / 60,
			);
			const seconds = (this.event.time_limit_seconds ?? 0) - minutes * 60 - hours * 3600;

			return {
				hours: String(hours).padStart(2, "0"),
				minutes: String(minutes).padStart(2, "0"),
				seconds: String(seconds).padStart(2, "0"),
			};
		},
		permalink(): string {
			return getExamPermalink(this.event);
		},
	},
	components: {
		Card,
		Btn,
		ParticipationThumbnail,
		CopyToClipboard,
		Tag,
	},
});
