import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "flex w-full" }
const _hoisted_3 = {
  key: 0,
  class: "absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "flex w-full" }
const _hoisted_6 = {
  key: 0,
  class: "absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _directive_wave = _resolveDirective("wave")!

  return (_ctx.tooltip)
    ? (_openBlock(), _createBlock(_component_Tooltip, {
        key: 0,
        noArrow: true,
        placement: 'bottom',
        textValue: _ctx.tooltip
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            disabled: _ctx.disabled || _ctx.loading,
            class: _normalizeClass(["relative overflow-hidden disabled:cursor-not-allowed", {
				'disabled:opacity-40 transition-all duration-100 ease-linear':
					_ctx.variant === 'secondary',
				'disabled:opacity-70': _ctx.variant !== 'secondary',
				'shadow-elevation-2 bg-light': _ctx.forceActive,
				'bg-success-light bg-opacity-30': _ctx.forceActive && _ctx.variant === 'success-borderless',
				'px-6 py-2 font-medium': !_ctx.outline && _ctx.size === 'base' && _ctx.variant !== 'transparent',
				'px-6 py-6.3px font-medium': _ctx.outline && _ctx.size === 'base' && _ctx.variant !== 'icon',
				'px-3.5 .5': _ctx.size === 'sm' && _ctx.variant !== 'icon',
				'px-1': _ctx.size === 'xs' && _ctx.variant !== 'icon',
				'px-14 py-2 text-lg font-medium': _ctx.size === 'lg' && _ctx.variant !== 'icon',
				'text-onPrimary bg-primary hover:bg-primary-dark shadow-btn':
					!_ctx.outline && _ctx.variant === 'primary',
				'text-onSecondary bg-secondary': !_ctx.outline && _ctx.variant === 'secondary',
				'hover:bg-secondary-dark shadow-btn':
					!_ctx.outline && _ctx.variant === 'secondary' && !_ctx.disabled,
				'text-danger-dark bg-danger shadow-btn': !_ctx.outline && _ctx.variant === 'danger',
				'text-danger-dark border-danger-dark bg-white border hover:bg-danger-dark hover:bg-opacity-5':
					_ctx.outline && _ctx.variant === 'danger',
				'text-success-dark bg-success-light shadow-btn':
					!_ctx.outline && _ctx.variant === 'success',
				'transition-colors duration-100': _ctx.outline,
				'focus:outline-primary': false && _ctx.variant === 'primary',
				'focus:outline-danger-dark': _ctx.variant === 'danger',
				'focus:outline-success': false && _ctx.variant === 'success',
				'hover:bg-light hover:shadow-inner': _ctx.variant === 'light',
				'text-primary bg-transparent hover:bg-primary hover:bg-opacity-5 border-primary border-1.5 ':
					_ctx.outline && _ctx.variant === 'primary',
				'text-lg font-medium text-primary hover:bg-light ':
					_ctx.variant === 'primary-borderless',
				'px-6': _ctx.variant === 'primary-borderless' && _ctx.size !== 'xs',
				'text-lg font-bold text-secondary hover:bg-light px-6 focus:bg-secondary-light focus:bg-opacity-30':
					_ctx.variant === 'secondary-borderless',
				'text-lg font-bold text-success hover:bg-light px-6 focus:bg-success-light focus:bg-opacity-30':
					_ctx.variant === 'success-borderless',
				'border-gray-300 bg-white hover:bg-primary hover:bg-opacity-10 hover:text-primary transition-colors duration-100':
					_ctx.variant === 'transparent',
				rounded: _ctx.variant !== 'transparent',
				'rounded-full bg-transparent hover:bg-gray-200 text-gray-600 icon-btn':
					_ctx.variant === 'icon',
				'icon-btn icon-btn-sm': _ctx.variant === 'icon' && _ctx.size === 'sm',
				'icon-btn icon-btn-xs': _ctx.variant === 'icon' && _ctx.size === 'xs',
				'icon-btn icon-btn-base': _ctx.variant === 'icon' && _ctx.size === 'base',
				'icon-btn icon-btn-lg': _ctx.variant === 'icon' && _ctx.size === 'lg',
			}])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", {
                class: _normalizeClass(["flex items-center mx-auto", _ctx.loading ? 'invisible' : ''])
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 2)
            ]),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                  _createVNode(_component_Spinner, {
                    size: 'md',
                    variant: 'gray'
                  })
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content")
          ], 10, _hoisted_1)), [
            [_directive_wave, _ctx.disabled ? false : {}]
          ])
        ]),
        _: 3
      }, 8, ["textValue"]))
    : _withDirectives((_openBlock(), _createElementBlock("button", {
        key: 1,
        disabled: _ctx.disabled || _ctx.loading,
        class: _normalizeClass(["relative overflow-hidden disabled:cursor-not-allowed", {
			'disabled:opacity-40 transition-all duration-100 ease-linear':
				_ctx.variant === 'secondary',
			'disabled:opacity-70': _ctx.variant !== 'secondary',
			'shadow-elevation-2 bg-light': _ctx.forceActive,
			'bg-success-light bg-opacity-30': _ctx.forceActive && _ctx.variant === 'success-borderless',
			'px-6 py-2 font-medium': !_ctx.outline && _ctx.size === 'base' && _ctx.variant !== 'transparent',
			'px-6 py-6.3px font-medium': _ctx.outline && _ctx.size === 'base' && _ctx.variant !== 'icon',
			'px-3.5 py-1.5': _ctx.size === 'sm' && _ctx.variant !== 'icon',
			'px-1': _ctx.size === 'xs' && _ctx.variant !== 'icon',
			'px-14 py-2 text-lg font-medium': _ctx.size === 'lg' && _ctx.variant !== 'icon',
			'text-onPrimary bg-primary hover:bg-primary-dark shadow-btn':
				!_ctx.outline && _ctx.variant === 'primary',
			'text-onSecondary bg-secondary': !_ctx.outline && _ctx.variant === 'secondary',
			'hover:bg-secondary-dark shadow-btn':
				!_ctx.outline && _ctx.variant === 'secondary' && !_ctx.disabled,
			'text-danger-dark bg-danger shadow-btn': !_ctx.outline && _ctx.variant === 'danger',
			'text-danger-dark border-danger-dark bg-white border hover:bg-danger-dark hover:bg-opacity-5':
				_ctx.outline && _ctx.variant === 'danger',
			'text-success-dark bg-success-light shadow-btn': !_ctx.outline && _ctx.variant === 'success',
			'transition-colors duration-100': _ctx.outline,
			'focus:outline-primary': false && _ctx.variant === 'primary',
			'focus:outline-danger-dark': _ctx.variant === 'danger',
			'focus:outline-success': false && _ctx.variant === 'success',
			'hover:bg-light hover:shadow-inner': _ctx.variant === 'light',
			'text-primary bg-transparent hover:bg-primary hover:bg-opacity-5 border-primary border-1.5 ':
				_ctx.outline && _ctx.variant === 'primary',
			'text-lg font-medium text-primary hover:bg-light ':
				_ctx.variant === 'primary-borderless',
			'px-6': _ctx.variant === 'primary-borderless' && _ctx.size !== 'xs',
			'text-lg font-bold text-secondary hover:bg-light px-6 focus:bg-secondary-light focus:bg-opacity-30':
				_ctx.variant === 'secondary-borderless',
			'text-lg font-bold text-success hover:bg-light px-6 focus:bg-success-light focus:bg-opacity-30':
				_ctx.variant === 'success-borderless',
			'border-gray-300 bg-white hover:bg-primary hover:bg-opacity-10 hover:text-primary transition-colors duration-100':
				_ctx.variant === 'transparent',
			rounded: _ctx.variant !== 'transparent',
			'rounded-full bg-transparent hover:bg-gray-200 text-gray-600 icon-btn':
				_ctx.variant === 'icon',
			'icon-btn icon-btn-sm': _ctx.variant === 'icon' && _ctx.size === 'sm',
			'icon-btn icon-btn-xs': _ctx.variant === 'icon' && _ctx.size === 'xs',
			'icon-btn icon-btn-base': _ctx.variant === 'icon' && _ctx.size === 'base',
			'icon-btn icon-btn-lg': _ctx.variant === 'icon' && _ctx.size === 'lg',
		}])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: _normalizeClass(["flex items-center mx-auto", _ctx.loading ? 'invisible' : ''])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2)
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
              _createVNode(_component_Spinner, {
                size: 'md',
                variant: 'gray'
              })
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "content")
      ], 10, _hoisted_4)), [
        [_directive_wave, _ctx.disabled ? false : {}]
      ])
}