
import CalendarInput from "@/components/ui/CalendarInput.vue";
import Dialog from "@/components/ui/Dialog.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "SchedulePublishDialog",
	props: {
		visible: {
			type: Boolean,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		initialValue: {
			type: Object as PropType<string | null>,
			default: null,
		},
	},
	watch: {
		visible(newVal) {
			if (!newVal) {
				this.value = null;
			} else {
				this.value = this.initialValue;
			}
		},
		value(newVal) {
			this.showErrors = newVal && this.invalid;
		},
	},
	data() {
		return {
			value: null as string | null,
			showErrors: false,
		};
	},
	methods: {
		onYes() {
			return this.$emit("confirm", this.value);
		},
		onNo() {
			return this.$emit("cancel");
		},
	},
	computed: {
		invalid() {
			if (this.value === null) {
				return true;
			}
			return new Date(this.value).getTime() - new Date().getTime() <= 5 * 1000 * 60;
		},
	},
	components: { Dialog, CalendarInput },
});
