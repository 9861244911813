
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "PublishedOnClassroom",
	props: {
		remoteObjectUrl: {
			type: String,
			default: "",
		},
	},
	methods: {},
	computed: {},
});
