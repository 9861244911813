
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import { isMaintenanceMode, DEFAULT_SERVER_MESSAGES } from "@/utils";
import { ErrorMessage } from "@/interfaces";

export default defineComponent({
	name: "ErrorView",
	props: {
		data: {
			type: Object as PropType<ErrorMessage>,
		},
	},
	computed: {
		isMaintenanceMode() {
			return isMaintenanceMode();
		},
		hasCustomMessage() {
			return (
				(this.data?.content ?? "").length > 0 &&
				!DEFAULT_SERVER_MESSAGES.includes(this.data?.content ?? "")
			);
		},
	},
	methods: {},
	components: { Btn },
});
