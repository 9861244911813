
import Btn from "@/components/ui/Btn.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import { Event, EventState, EventTimeLimitRule } from "@/models";
import { defineComponent } from "@vue/runtime-core";

import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import { courseSelectionOptions } from "@/const";
import RadioGroup from "@/components/ui/RadioGroup.vue";
import { isDemoMode } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import StudentScopesBanner from "../../integrations/classroom/components/StudentScopesBanner.vue";
export default defineComponent({
	name: "ExamPreview",
	mixins: [courseIdMixin, eventIdMixin, loadingMixin],
	components: {
		Timestamp,
		Btn,
		SlotSkeleton,
		NumberInput,
		RadioGroup,
		StudentScopesBanner,
	},
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getCourses(); // TODO is this necessary?
			await this.mainStore.getEventPreview({
				courseId: this.courseId,
				eventId: this.eventId,
			});
		});

		this.dirtyCourse = this.metaStore.user.course;
		this.dirtyMat = this.metaStore.user.mat;

		if (this.mainStore.previewingEvent?.participation_exists) {
			this.$router.push({
				name: "ExamParticipationPage",
				params: {
					examId: this.eventId,
				},
			});
		}
	},
	data() {
		return {
			EventState,
			EventTimeLimitRule,
			dirtyMat: "",
			dirtyCourse: "",
			courseSelectionOptions,
		};
	},
	methods: {
		async onSaveStudentData() {
			await this.withLocalLoading(async () =>
				this.metaStore.patchUser({
					userId: this.metaStore.user.id,
					changes: { mat: this.dirtyMat, course: this.dirtyCourse },
				}),
			);
			this.metaStore.showSuccessFeedback();
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		timeLimitMinutes() {
			return (
				Math.floor(
					((this.mainStore.previewingEvent?.time_limit_seconds ?? 0) / 60) * 10,
				) / 10
			);
		},
		isDemoMode() {
			return isDemoMode();
		},
		canParticipate() {
			return (
				this.mainStore.previewingEvent?.state !== EventState.PLANNED &&
				this.mainStore.previewingEvent?.state !== EventState.CLOSED
			);
		},
	},
});
