import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ag-cell-label-container",
  role: "presentation"
}
const _hoisted_2 = {
  ref: "eMenu",
  class: "ag-header-icon ag-header-cell-menu-button"
}
const _hoisted_3 = {
  ref: "eLabel",
  class: "ag-header-cell-label",
  role: "presentation"
}
const _hoisted_4 = {
  ref: "eSortOrder",
  class: "ag-header-icon ag-sort-order"
}
const _hoisted_5 = {
  ref: "eSortAsc",
  class: "ag-header-icon ag-sort-ascending-icon"
}
const _hoisted_6 = {
  ref: "eSortDesc",
  class: "ag-header-icon ag-sort-descending-icon"
}
const _hoisted_7 = {
  ref: "eSortNone",
  class: "ag-header-icon ag-sort-none-icon"
}
const _hoisted_8 = {
  ref: "eText",
  class: "ag-header-cell-text",
  style: {"white-space":"break-spaces","max-width":"150px"},
  role: "columnheader"
}
const _hoisted_9 = {
  ref: "eFilter",
  class: "ag-header-icon ag-filter-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, null, 512),
      _createElementVNode("span", _hoisted_5, null, 512),
      _createElementVNode("span", _hoisted_6, null, 512),
      _createElementVNode("span", _hoisted_7, null, 512),
      _createElementVNode("span", _hoisted_8, [
        _createVNode(_component_router_link, {
          class: "hover:text-primary hover:underline overflow-ellipsis overflow-hidden",
          target: "_blank",
          style: {"display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"},
          to: {
						name: 'ExamResults',
						params: { examId: _ctx.examId },
					}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.params.displayName), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ], 512),
      _createElementVNode("span", _hoisted_9, null, 512)
    ], 512)
  ]))
}