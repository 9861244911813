
import Spinner from "@/components/ui/Spinner.vue";
import { setErrorNotification } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { CLASSROOM_STUDENT_SCOPES, CLASSROOM_TEACHER_SCOPES } from "../classroom/const";
import { useGoogleIntegrationsStore } from "../stores/googleIntegrationsStore";
import GoogleScopesButton from "./GoogleScopesButton.vue";
export default defineComponent({
	name: "GoogleScopeChecker",
	props: {
		role: {
			type: String as PropType<"teacher" | "student">,
			required: true,
		},
		// scopes: {
		// 	type: Array as PropType<string[]>,
		// 	required: true,
		// },
	},
	async created() {
		// TODO remove setTimeout in production, it's to avoid local db lock
		setTimeout(async () => await this.loadAuthorizedScopes(), 1000);
	},
	watch: {
		outstandingScopesLength(newVal) {
			if (newVal === 0) {
				this.$emit("scopesOk");
			} else {
				this.$emit("scopesCheckFailed");
			}
		},
	},
	data() {
		return {
			loading: true,
			error: false,
		};
	},
	methods: {
		async loadAuthorizedScopes() {
			this.loading = true;
			try {
				await this.googleIntegrationStore.getAuthorizedScopes();
			} catch (e) {
				this.error = true;
				setErrorNotification(e);
			} finally {
				this.loading = false;
			}
		},
	},
	computed: {
		...mapStores(useGoogleIntegrationsStore),
		scopes() {
			return this.role === "teacher"
				? CLASSROOM_TEACHER_SCOPES
				: CLASSROOM_STUDENT_SCOPES;
		},
		outstandingScopes() {
			// List of required scopes the user hasn't granted yet
			const granted = this.googleIntegrationStore.authorizedScopes;
			return this.scopes.filter(s => !granted.includes(s));
		},
		outstandingScopesLength() {
			if (!this.loading) {
				return this.outstandingScopes.length;
			}
			return undefined;
		},
	},
	components: { Spinner, GoogleScopesButton },
});
