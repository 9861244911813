
import { getCourseTopicNodes } from "@/api";
import Btn from "@/components/ui/Btn.vue";
import CloudSaveStatus from "@/components/ui/CloudSaveStatus.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import FileUpload from "@/components/ui/FileUpload.vue";
import TextEditor from "@/components/ui/TextEditor.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import IntegrationSwitch from "@/integrations/classroom/components/IntegrationSwitch.vue";
import PublishedOnClassroom from "@/integrations/classroom/components/PublishedOnClassroom.vue";
import { GoogleClassroomMaterialTwin } from "@/integrations/classroom/interfaces";
import { useGoogleIntegrationsStore } from "@/integrations/stores/googleIntegrationsStore";
import { SelectableOption } from "@/interfaces";
import { courseIdMixin, savingMixin } from "@/mixins";
import {
	CourseTreeNode,
	CourseTreeNodeType,
	FileNode as IFileNode,
	getBlankFileNode,
	LessonNode,
	LessonNodeState,
	TopicNode,
} from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { getFormattedTimestamp, setErrorNotification } from "@/utils";
import { lessonNodeValidation } from "@/validation/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";
import { mapStores } from "pinia";
import FileNode from "../node/FileNode.vue";
import { nodeEditorEmits, nodeEditorProps } from "../shared";
import SchedulePublishDialog from "./SchedulePublishDialog.vue";
export default defineComponent({
	name: "LessonNodeEditor",
	props: {
		modelValue: {
			type: Object as PropType<LessonNode>,
			required: true,
		},
		...nodeEditorProps,
	},
	emits: {
		...nodeEditorEmits,
	},
	mixins: [courseIdMixin, savingMixin],
	validations() {
		return {
			modelValue: lessonNodeValidation,
		};
	},
	setup() {
		const v = useVuelidate();
		return { v$: v };
	},
	data() {
		return {
			LessonNodeState,
			creatingAttachment: false,
			topics: [] as TopicNode[],
			loadingTopics: false,
			loadingChildren: false,
			attachmentUploadProgress: undefined as undefined | number,
			publishToClassroom: true,
			showClassroomIntegrationSwitch: false,
			googleClassroomMaterialTwin: null as null | GoogleClassroomMaterialTwin,
			showScheduleDialog: false,
			canceledScheduledPublish: false,
		};
	},
	async created() {
		await this.mainStore.getCourseRootId({ courseId: this.courseId });
		this.showClassroomIntegrationSwitch =
			await this.googleIntegrationStore.isGoogleClassroomIntegrationActive(this.courseId);
		this.checkForMaterialTwin();
		await Promise.all([
			(async () => {
				this.loadingChildren = true;
				try {
					await this.mainStore.getCourseTreeNodeChildren({
						courseId: this.courseId,
						nodeId: this.modelValue.id,
						fromFirstPage: true,
					});
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.loadingChildren = false;
				}
			})(),
			(async () => {
				this.loadingTopics = true;
				try {
					this.topics = await getCourseTopicNodes(this.courseId);
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.loadingTopics = false;
				}
			})(),
		]);
	},
	methods: {
		async onSchedule(datetime) {
			if (datetime !== null) {
				this.v$.$touch();
			}
			if (datetime === null || this.v$.$errors.length === 0) {
				const save = datetime !== null;
				this.onNodeChange("schedule_publish_at", datetime, save);
				if (datetime === null) {
					this.canceledScheduledPublish = true;
				}
			} else {
				this.showScheduleDialog = false;
			}
		},
		async checkForMaterialTwin() {
			this.googleClassroomMaterialTwin =
				await this.googleIntegrationStore.getGoogleClassroomMaterialTwin(
					this.modelValue.id,
				);
		},
		onNodeChange<K extends keyof CourseTreeNode>(
			key: any, //K,
			value: any, //CourseTreeNode[K],
			save = false,
		) {
			// TODO extract shared behavior (mixin?)
			this.$emit("patchNode", { key, value, save });
		},
		onSave() {
			if (
				(this.modelValue.state !== LessonNodeState.DRAFT ||
					this.modelValue.schedule_publish_at) &&
				this.v$.$invalid
			) {
				this.v$.$touch();
				return;
			}
			this.$emit("save");
		},
		onBlur() {
			this.$emit("blur");
		},
		onParentChange(parentId: string) {
			this.onNodeChange("parent_id", parentId);
		},
		async onPublish() {
			if (
				this.modelValue.schedule_publish_at &&
				!confirm(
					_("course_tree.scheduled_confirm_publish_now_1") +
						getFormattedTimestamp(this.modelValue.schedule_publish_at) +
						_("course_tree.scheduled_confirm_publish_now_2"),
				)
			) {
				return;
			}
			this.v$.$touch();
			if (this.v$.$errors.length === 0) {
				this.$emit("updateState", {
					newState: LessonNodeState.PUBLISHED,
					params: {
						fireIntegrationEvent: this.publishToClassroom,
					},
				});
			}
		},
		async onCreateAttachment(file) {
			this.creatingAttachment = true;
			const onUploadProgress = (e: { loaded: number; total: number }) => {
				this.attachmentUploadProgress = (e.loaded / e.total) * 100;
				// make loader indeterminate after upload completes for
				//better visual feedback to the user
				if (this.attachmentUploadProgress === 100) {
					setTimeout(() => {
						this.attachmentUploadProgress = undefined;
					}, 100);
				}
			};
			try {
				await this.mainStore.createCourseTreeNode({
					courseId: this.courseId,
					node: { ...getBlankFileNode(this.modelValue.id), file },
					config: {
						onUploadProgress,
					},
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.creatingAttachment = false;
			}
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore, useGoogleIntegrationsStore),
		hasGoogleClassroomTwin() {
			return this.googleClassroomMaterialTwin !== null;
		},
		// TODO refactor, duplicated with other editors
		topicsAsOptions(): SelectableOption[] {
			return [
				{
					value: this.mainStore.courseIdToTreeRootId[this.courseId],
					content: _("course_tree.no_topic"),
				},
				...this.topics.map(t => ({
					value: t.id,
					content: t.name,
				})),
			];
		},
		fileChildren(): IFileNode[] {
			return (
				this.mainStore.paginatedChildrenByNodeId[this.modelValue.id]?.data ?? []
			).filter(c => c.resourcetype === CourseTreeNodeType.FileNode) as IFileNode[];
		},
		attachmentProxy: {
			get() {
				return [];
			},
			async set(val: any) {
				await this.onCreateAttachment(val);
			},
		},
	},
	components: {
		TextInput,
		TextEditor,
		CloudSaveStatus,
		Btn,
		//Timestamp,
		FileUpload,
		FileNode,
		//LinearProgress,
		Dropdown,
		IntegrationSwitch,
		PublishedOnClassroom,
		SchedulePublishDialog,
		Timestamp,
	},
});
