
import { courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";

import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import { EventType } from "@/models";
import { getTranslatedString as _ } from "@/i18n";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import { EventSearchFilter } from "@/api";
import Spinner from "@/components/ui/Spinner.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import ExamCard from "../../../components/student/ExamCard.vue";

export default defineComponent({
	components: {
		SkeletonCard,
		VueEternalLoading,
		Spinner,
		ExamCard,
	},
	name: "PublicExamsList",
	mixins: [courseIdMixin, loadingMixin],
	async created() {
		await this.withFirstLoading(
			async () =>
				await this.mainStore.getEvents({
					courseId: this.courseId,
					filters: {
						event_type: EventType.EXAM,
					} as EventSearchFilter,
				}),
		);
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
		};
	},
	methods: {
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				// TODO get more exams
				// const moreResults = await this.mainStore.getCourseEventParticipations({
				// 	courseId: this.courseId,
				// 	fromFirstPage: false,
				// 	filter: { event_type: EventType.EXAM } as EventParticipationSearchFilter,
				// });
				// if (!moreResults) {
				// 	noMore();
				// } else {
				// 	loaded();
				// }
			} catch {
				error();
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
	},
});
