
import { courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";
import AbstractEventParticipationSlot from "@/components/shared/AbstractEventParticipationSlot.vue";

import {
	EventParticipation,
	EventParticipationSlot,
	EventParticipationSlotAssessment,
	EventParticipationState,
	EventState,
	EventType,
	Exercise,
} from "@/models";
import Timestamp from "@/components/ui/Timestamp.vue";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Btn from "@/components/ui/Btn.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { getTranslatedString } from "@/i18n";
import ExerciseSolutionContainer from "@/components/shared/ExerciseSolution/ExerciseSolutionContainer.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { setErrorNotification } from "@/utils";

export default defineComponent({
	name: "EventParticipationFull",
	mixins: [eventIdMixin, courseIdMixin, loadingMixin],
	props: {
		showSolutionAndScores: {
			type: Boolean,
			default: true,
		},
		allowEditAssessment: {
			type: Boolean,
			default: false,
		},
		showAssessmentCard: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AbstractEventParticipationSlot,
		Timestamp,
		SlotSkeleton,
		Btn,
		TextInput,
		ExerciseSolutionContainer,
	},
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getCurrentEventParticipation({
				courseId: this.courseId,
				eventId: this.eventId,
				participationId: this.participationId,
			});
		});

		// participation is still in progress and exam is
		// still open, redirect to participation page
		if (
			!this.allowEditAssessment && // not in teacher mode
			this.mainStore.currentEventParticipation?.state !==
				EventParticipationState.TURNED_IN &&
			// TODO assumes that currentEventParticipation contains Event
			this.mainStore.currentEventParticipation?.event.state === EventState.OPEN
		) {
			this.$router.push({
				name: "ExamParticipationPage",
				params: {
					examId: this.eventId,
				},
			});
		}

		if (this.showSolutionAndScores) {
			// TODO load solutions for exercises as they become visible
			(this.mainStore.currentEventParticipation?.slots ?? []).forEach(async s => {
				this.slotsLoadingSolution[s.id] = true;
				try {
					await this.mainStore.getSolutionsByExercise({
						courseId: this.courseId,
						exerciseId: s.exercise.id,
						filter: null,
						fromFirstPage: true,
					});
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.slotsLoadingSolution[s.id] = false;
				}
			});
		}
	},
	data() {
		return {
			slotsLoadingSolution: {} as Record<string, boolean>,
			slotsAssessmentControlsVisibility: {} as Record<string, boolean>,
			slotsAssessmentLoading: {} as Record<string, boolean>,
			showEditScore: false,
			dirtyScore: undefined as string | null | undefined,
			EventType,
			assessmentLoading: false,
		};
	},
	methods: {
		onGoBack() {
			if (window.history.length > 1) {
				this.$router.back();
			} else {
				// default "back" location
				this.$router.push({
					name: "ExamProgress",
				});
			}
		},
		getSolutionsForExercise(exercise: Exercise) {
			return this.mainStore.getPaginatedSolutionsByExerciseId(exercise.id).data;
		},
		onShowEditScore() {
			this.dirtyScore = this.mainStore.currentEventParticipation?.score;
			this.showEditScore = true;
		},
		async onUndoScoreEdit() {
			if (confirm(getTranslatedString("event_assessment.undo_overall_score_edit"))) {
				this.dirtyScore = null;
				await this.onHideEditScore();
			}
		},
		async onHideEditScore(discard = false) {
			if (discard) {
				this.showEditScore = false;
				return;
			}
			this.assessmentLoading = true;
			await this.withLocalLoading(
				async () =>
					await this.mainStore.partialUpdateCurrentEventParticipation({
						courseId: this.courseId,
						changes: {
							score: this.dirtyScore,
						},
					}),
			);
			this.assessmentLoading = false;
			this.showEditScore = false;
		},
		async onSlotSaveAssessment(
			slot: EventParticipationSlot,
			changes: { score: number | null; comment: string },
		) {
			this.slotsAssessmentLoading[slot.id] = true;
			try {
				await this.mainStore.partialUpdateCurrentEventParticipationSlot({
					courseId: this.courseId,
					slotId: slot.id,
					changes,
					mutate: false,
					forceStudent: false, // update performed as a teacher
				});
				// re-fetch participation to register any updates (e.g. the score property)
				await this.mainStore.getCurrentEventParticipation({
					courseId: this.courseId,
					eventId: this.eventId,
					participationId: this.participationId,
				});
				this.slotsAssessmentControlsVisibility[slot.id] = false;
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.slotsAssessmentLoading[slot.id] = false;
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
		participationId(): string {
			return this.$router.currentRoute.value.params.participationId as string;
		},
		someSlotsPending(): boolean {
			return (
				this.mainStore.currentEventParticipation?.slots.some(
					s => s.score === null || s.sub_slots.some(r => r.score === null),
				) ?? true
			);
		},
	},
});
