
import { defineComponent } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
export default defineComponent({
	name: "Toggle",
	props: {
		modelValue: [Boolean, Array],
		value: {
			default: null,
		},
		labelOnLeft: {
			type: Boolean,
			default: false,
		},
		overrideId: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		modelValue() {
			this.syncCheckboxState();
		},
	},
	created() {
		this.id = uuid4();
	},
	data() {
		return {
			id: "",
		};
	},
	methods: {
		syncCheckboxState() {
			// this is needed to keep the `checked` attribute of the checkbox element
			// in sync with the value of `modelValue`. whenever either an `update:modelValue`
			// event is emitted, or the value of the `modelValue` prop changes, we make sure
			// the checkbox is checked if and only if the value is truthy
			this.$nextTick(() => {
				const checkboxElement = document.getElementById(this.id) as HTMLInputElement;
				if (checkboxElement && checkboxElement.checked !== this.modelValue) {
					checkboxElement.checked = this.modelValue as boolean;
				}
			});
		},
	},
	computed: {
		proxyModelValue: {
			get() {
				return this.modelValue;
			},
			set(val: unknown) {
				this.$emit("update:modelValue", val);
				this.syncCheckboxState();
			},
		},
	},
});
