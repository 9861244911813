
/* eslint-disable */
import Btn from "@/components/ui/Btn.vue";
import { inject, toRefs } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { loadingMixin } from "@/mixins";
import {
	isDemoMode,
	logAnalyticsEvent,
	redirectToMainView,
	setErrorNotification,
} from "@/utils";
import { getTranslatedString as _ } from "@/i18n";
import { demoLoginTourSteps, tourOptions } from "@/const";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";

import type { CallbackTypes } from "vue3-google-login";

const DEMO_TOUR_KEY = "demo_tour_taken";

export default defineComponent({
	name: "Login",
	components: {
		Btn,
	},
	data() {
		return {
			loadingLogin: true,
			disableLogin: false,
			demoLoginTourSteps,
			tourOptions,
			showIcons: false,
			loginAttemptWithUnauthorizedEmailAddress: false,
		};
	},
	mixins: [loadingMixin],
	methods: {
		onTourFinish() {
			this.disableLogin = false;
			localStorage.setItem(DEMO_TOUR_KEY, "true");
		},
		async onLogin(response: Parameters<CallbackTypes.TokenResponseCallback>[0]) {
			await this.withLocalLoading(async () => {
				const token = response.access_token;
				await this.metaStore.convertToken(token);
				await this.metaStore.getUserData();
				redirectToMainView();
			}, setErrorNotification);
		},
		onLoginAttemptWithUnauthorizedEmailAddress() {
			logAnalyticsEvent("unauth_login_attempt", {});
			this.loginAttemptWithUnauthorizedEmailAddress = true;
		},
		redirectToDemoPage() {
			logAnalyticsEvent("loginFailedRedirectToDemo", {});
			const redirectUrl =
				process.env.VUE_APP_DEMO_REDIRECT_URL ?? "http://localhost:8081";
			window.location.href = redirectUrl;
		},
	},
	setup() {
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		return {
			Vue3GoogleOauth,
		};
	},
	created() {
		if (this.metaStore.isAuthenticated) {
			redirectToMainView();
		}
	},
	mounted() {
		setTimeout(() => (this.showIcons = true), 10);
	},
	computed: {
		...mapStores(useMetaStore),
	},
});
