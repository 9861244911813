import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = { class: "flex h-full md:space-x-10" }
const _hoisted_3 = { class: "w-full pb-4" }
const _hoisted_4 = { class: "mb-8 mt-4" }
const _hoisted_5 = {
  class: "mb-2",
  style: {"line-height":"1.2"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumbs = _resolveComponent("BreadCrumbs")!
  const _component_StudentScopesBanner = _resolveComponent("StudentScopesBanner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMobileSidebar = false)),
          class: "fixed z-50 w-full h-full opacity-50 bg-dark"
        }, null, 512), [
          [_vShow, _ctx.showMobileSidebar]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.routeTitle), 1),
          _createVNode(_component_BreadCrumbs, { route: _ctx.$route }, null, 8, ["route"])
        ]),
        (!_ctx.metaStore.user.is_teacher)
          ? (_openBlock(), _createBlock(_component_StudentScopesBanner, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}