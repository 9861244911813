
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
	name: "NumberInput",
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [Number, String],
			required: true,
		},
		rightIcon: {
			type: String,
			default: "",
		},
		leftIcon: {
			type: String,
			default: "",
		},
		iconFilled: {
			type: Boolean,
			default: false,
		},
		min: {
			type: Number,
			default: -1000000000,
		},
		max: {
			type: Number,
			default: 1000000000,
		},
		small: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: true,
		},
		elementId: {
			type: String,
			default: "",
		},
	},
	// methods: {
	//   onInput(value: string) {
	//     this.$emit("update:modelValue", value);
	//   },
	// },
	computed: {
		proxyModelValue: {
			get() {
				return this.modelValue;
			},
			set(value: any) {
				// TODO allow 2 decimal digits with "^\d*(\.\d{0,2})?$"
				this.$emit("update:modelValue", value);
			},
		},
	},
});
