import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "focus-within:text-lg material-icons-outlined text-muted -mb-2 pt-2.5",
  style: {"margin-left":"13px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    title: _ctx.$t('event_participation_states.' + _ctx.params.value),
    class: _normalizeClass([_ctx.params.value === _ctx.EventParticipationState.IN_PROGRESS ? 'mt-1' : 'mt-1.5'])
  }, [
    (_ctx.params.value !== _ctx.EventParticipationState.CLOSED_BY_TEACHER)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.participationStateIcons[_ctx.params.value as EventParticipationState][0]), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}