
import {
	EventParticipation,
	EventParticipationState,
	EventState,
	EventType,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Card from "../ui/Card.vue";
import Timestamp from "../ui/Timestamp.vue";
import Btn from "../ui/Btn.vue";
import { courseIdMixin } from "@/mixins";
import ParticipationThumbnail from "./ParticipationThumbnail.vue";

export default defineComponent({
	name: "EventParticipationPreview",
	props: {
		participation: {
			type: Object as PropType<EventParticipation>,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin],
	data() {
		return {
			EventType,
		};
	},
	computed: {
		isParticipable(): boolean {
			return (
				this.participation.event.state === EventState.OPEN &&
				this.participation.state === EventParticipationState.IN_PROGRESS
			);
		},
		showThumbnail(): boolean {
			return this.participation.event.event_type === EventType.SELF_SERVICE_PRACTICE;
		},
	},
	components: {
		Card,
		Timestamp,
		Btn,
		ParticipationThumbnail,
	},
});
