
const SHOWN_ARTICLES = 10;

import { defineComponent } from "@vue/runtime-core";
import DraggablePopup from "@/components/ui/DraggablePopup.vue";
import { getArticle, getArticles, HelpCenterArticle } from "@/helpCenter";
import HelpCenterArticleFull from "./HelpCenterArticleFull.vue";
import { courseIdMixin, mediaQueryMixin } from "@/mixins";
import { isDemoMode } from "@/utils";
import { logAnalyticsEvent } from "@/utils";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
export default defineComponent({
	name: "HelpCenter",
	components: { DraggablePopup, HelpCenterArticleFull },
	mixins: [courseIdMixin, mediaQueryMixin],
	props: {
		allowGoBack: {
			type: Boolean,
			default: true,
		},
	},
	created() {
		logAnalyticsEvent("openedHelpCenter", { path: this.$route.fullPath });
	},
	methods: {
		getArticleRelevance(article: HelpCenterArticle): number {
			const routeTags: string[] = this.$route.meta.tags as string[];
			return article.tags.filter(t => routeTags.includes(t)).length;
		},
	},
	data() {
		return {
			showAll: false,
		};
	},
	computed: {
		...mapStores(useMetaStore),
		isDemo() {
			return isDemoMode();
		},
		sortedArticles(): HelpCenterArticle[] {
			return [...getArticles()].sort(
				(a, b) => this.getArticleRelevance(b) - this.getArticleRelevance(a),
			);
		},
		relevantArticles(): HelpCenterArticle[] {
			return this.sortedArticles.filter(a => this.getArticleRelevance(a) > 0).slice(0, 3);
		},
		irrelevantArticles(): HelpCenterArticle[] {
			return this.sortedArticles
				.filter(a => !this.relevantArticles.map(r => r.id).includes(a.id))
				.slice(0, this.showAll ? Infinity : SHOWN_ARTICLES);
		},
		selectedArticle(): HelpCenterArticle | undefined {
			if (this.metaStore.helpCenterSelectedArticleId === null) {
				return undefined;
			}
			return getArticle(this.metaStore.helpCenterSelectedArticleId);
		},
	},
});
