
import Toggle from "@/components/ui/Toggle.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "IntegrationSwitch",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
	},
	methods: {},
	computed: {},
	components: { Toggle },
});
