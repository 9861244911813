
import { defineComponent } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import MultiIcon from "@/components/ui/MultiIcon.vue";
import { SelectableOption } from "../../interfaces";
import { MDCRadio } from "@material/radio";
import { PropType } from "vue";

export default defineComponent({
	name: "RadioGroup",
	props: {
		options: {
			type: Object as PropType<SelectableOption[]>,
			required: true,
		},
		modelValue: {
			type: Array as PropType<unknown>,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		//MultiIcon,
	},
	watch: {
		disabled: {
			handler() {
				this.$nextTick(() => {
					this.radios.forEach(radio => {
						radio.disabled = this.disabled;
					});
				});
			},
			immediate: true,
		},
	},

	mounted() {
		(this.options as SelectableOption[]).map((option, i) => {
			const radio = new MDCRadio(
				document.getElementById(this.id + "-radio-" + i) as Element,
			);
			this.radios.push(radio);

			radio.checked = this.modelValue == option.value;
			radio.disabled = this.disabled;

			// attach event listener
			radio.listen("input", e => {
				console.log(e);
				this.$emit("update:modelValue", option.value);
			});
		});
	},
	data() {
		return {
			showFeedback: false,
			id: "a" + uuid4(),
			radios: [] as any,
		};
	},
	methods: {
		onInput(value: string, inputEvent: Event) {
			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			//(inputEvent.target as unknown as { checked: boolean }).checked = false;
			inputEvent.preventDefault();
			this.$emit("update:modelValue", value);
		},
	},
});
