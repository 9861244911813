
const VISITED_INSIGHTS_TOUR_KEY = "VISITED_INSIGHTS_TOUR_KEY";

/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable from "@/components/ui/DataTable.vue";
import { blockingDialogMixin, courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import {
	AssessmentVisibility,
	Event,
	EventParticipation,
	EventParticipationSlot,
	EventParticipationSlotAssessment,
	EventParticipationState,
	EventState,
	EventTemplateRule,
	ParticipationAssessmentProgress,
	User,
	userMatchesSearch,
} from "@/models";
import { defineComponent } from "@vue/runtime-core";

import { getTranslatedString as _ } from "@/i18n";
import { CellClickedEvent, ColDef, RowClassParams, RowNode } from "ag-grid-community";
import { icons as assessmentStateIcons } from "@/assets/assessmentStateIcons";
import { icons as participationStateIcons } from "@/assets/participationStateIcons";
import Dialog from "@/components/ui/Dialog.vue";
import AbstractEventParticipationSlot from "@/components/shared/AbstractEventParticipationSlot.vue";
import { DialogData } from "@/interfaces";
import Btn from "@/components/ui/Btn.vue";
import CsvParticipationDownloader from "@/components/teacher/CsvParticipationDownloader.vue";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import {
	examInsightsPageTourSteps,
	getEventParticipationMonitorHeaders,
	tourOptions,
} from "@/const";

import Spinner from "@/components/ui/Spinner.vue";
import {
	areAllParticipationsFullyAssessed,
	getParticipationsAverageProgress,
} from "@/reports";

import EventParticipationSlotScoreRenderer from "@/components/datatable/EventParticipationSlotScoreRenderer.vue";
import EventParticipationSlotCompletionRenderer from "@/components/datatable/EventParticipationSlotCompletionRenderer.vue";
import EventParticipationEmailRenderer from "@/components/datatable/EventParticipationEmailRenderer.vue";
import EventParticipationStateRenderer from "@/components/datatable/EventParticipationStateRenderer.vue";
import EventParticipationAssessmentStateRenderer from "@/components/datatable/EventParticipationAssessmentStateRenderer.vue";
import EventParticipationScoreRenderer from "@/components/datatable/EventParticipationScoreRenderer.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { setErrorNotification } from "@/utils";
import IntegrationSwitch from "@/integrations/classroom/components/IntegrationSwitch.vue";
import { GoogleClassroomCourseWorkTwin } from "@/integrations/classroom/interfaces";
import { useGoogleIntegrationsStore } from "@/integrations/stores/googleIntegrationsStore";
import TextInput from "@/components/ui/TextInput.vue";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";

export default defineComponent({
	components: {
		DataTable,
		Dialog,
		AbstractEventParticipationSlot,
		Btn,
		CsvParticipationDownloader,
		SkeletonCard,
		Spinner,
		/** Cell renderers required by Ag-grid */
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationSlotScoreRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationSlotCompletionRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationEmailRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationStateRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationAssessmentStateRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationScoreRenderer,
		IntegrationSwitch,
		TextInput,
		DropdownMenu,
	},
	name: "EventParticipationsMonitor",
	props: {
		refreshData: {
			type: Boolean,
			default: true,
		},
		allowEditParticipations: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin, eventIdMixin, loadingMixin, blockingDialogMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getEventParticipations({
				courseId: this.courseId,
				eventId: this.eventId,
				mutate: true,
			});
			// TODO gridApi could be null (doesn't really happen in production) - what did this line solve?
			//this.gridApi.refreshCells({ force: true });
			await this.mainStore.getEvent({
				courseId: this.courseId,
				eventId: this.eventId,
				includeDetails: false,
			});
		});

		if (this.resultsMode) {
			await this.checkForCourseworkTwin();
		}

		if (this.resultsMode && !(VISITED_INSIGHTS_TOUR_KEY in localStorage)) {
			setTimeout(() => (this.$tours as any)["examInsightsTour"].start(), 200);
			localStorage.setItem(VISITED_INSIGHTS_TOUR_KEY, "true");
		}

		if (this.refreshData) {
			// TODO improve this to avoid repeated calls before the last one completes, and possibly adjusting for network speed
			this.setDataRefreshInterval(10000);
		}

		const runningSlotsCheckFn = () =>
			this.mainStore.eventParticipations
				.flatMap(p => p.slots)
				.some(s => s.execution_results && s.execution_results.state === "running");

		// if the exam is closed but there are participation slots with
		// code still running, periodically refetch data until all slots
		// are settled down
		if (this.resultsMode && runningSlotsCheckFn()) {
			this.setDataRefreshInterval(2000, () => {
				if (!runningSlotsCheckFn()) {
					clearInterval(this.refreshHandle as number);
					this.refreshHandle = null;
				}
			});
		}
		// setTimeout(() => this.columnApi.autoSizeAllColumns(false), 5000);
	},

	beforeRouteLeave() {
		if (this.refreshHandle != null) {
			clearInterval(this.refreshHandle);
		}
	},
	data() {
		return {
			refreshHandle: null as number | null,
			EventState,
			editingSlot: null as EventParticipationSlot | null,
			editingSlotDirty: null as EventParticipationSlot | null,
			editingFullName: "",
			editingParticipationId: "",
			gridApi: null as any,
			columnApi: null as any,
			selectedParticipationsIds: [] as string[],
			dispatchingCall: false,
			searchText: "",

			// dialog functions
			showAssessmentEditorDialog: false,

			// show banners
			showThereAreUnpublishedResultsBanner: true,
			showRestrictedModeBanner: true,
			showThereArePendingAssessmentsBanner: true,
			showAllAssessmentsPublishedBanner: true,

			//tour
			tourOptions,
			examInsightsPageTourSteps,

			EventParticipationState,
			participationStateIcons,

			googleClassroomCourseWorkTwin: null as null | GoogleClassroomCourseWorkTwin,
			publishToClassroom: true,
			sortingOptionsExpanded: false,

			selectedSortingOption: 0,
		};
	},
	methods: {
		areAllParticipationsFullyAssessed,
		async checkForCourseworkTwin() {
			this.googleClassroomCourseWorkTwin =
				await this.googleIntegrationStore.getGoogleClassroomCourseWorkTwin(this.eventId);
		},
		setDataRefreshInterval(interval: number, callback?: any) {
			this.refreshHandle = setInterval(async () => {
				await this.mainStore.getEventParticipations({
					courseId: this.courseId,
					eventId: this.eventId,
					mutate: true,
				});
				if (typeof callback === "function") {
					callback();
				}
			}, interval);
		},

		onUpdateSlotAssessment(event: {
			slot: EventParticipationSlot;
			payload: [keyof EventParticipationSlotAssessment, any];
		}) {
			if (this.editingSlotDirty) {
				this.editingSlotDirty[event.payload[0]] = event.payload[1];
			}
		},
		isRowSelectable(row: RowNode) {
			/**
			 * Used by ag grid to determine whether the row is selectable
			 */
			if (this.resultsMode) {
				return (
					row.data.state == ParticipationAssessmentProgress.FULLY_ASSESSED &&
					row.data.visibility != AssessmentVisibility.PUBLISHED
				);
			}
			return true;
		},
		getRowId(data: any) {
			return data.id;
		},
		getRowClassRules() {
			return {
				"bg-success-important hover:bg-success-important": (params: RowNode) =>
					this.resultsMode && params.data.visibility === AssessmentVisibility.PUBLISHED,
				"bg-danger-important hover:bg-danger-important": (params: RowNode) =>
					!this.resultsMode &&
					this.mainStore.getEventParticipationById(params.data.id)?.state ===
						EventParticipationState.CLOSED_BY_TEACHER,
			};
		},
		onSelectionChanged() {
			// copy the id's of the selected participations
			this.selectedParticipationsIds = this.gridApi
				?.getSelectedNodes()
				.map((n: any) => n.data.id);
		},
		deselectAllRows() {
			this.gridApi.deselectAll();
			this.selectedParticipationsIds = [];
		},
		async onCellClicked(event: CellClickedEvent) {
			if (event.colDef.field?.startsWith("slot") && this.resultsMode) {
				this.onOpenAssessmentEditorDialog(event.data.id, event.value);
			}
		},
		async onOpenAssessmentEditorDialog(
			participationId: string,
			slot: EventParticipationSlot,
		) {
			this.editingSlot = slot;
			this.editingParticipationId = participationId;
			this.showAssessmentEditorDialog = true;

			// fetch slot that is being edited to have the full details
			await this.withLocalLoading(
				async () =>
					await this.mainStore.getEventParticipationSlot({
						courseId: this.courseId,
						slotId: (this.editingSlot as EventParticipationSlot).id,
						participationId,
						eventId: this.eventId,
					}),
			);
			// deep copy slot to prevent affecting the original one while editing
			this.editingSlotDirty = JSON.parse(JSON.stringify(this.editingSlot));
			this.editingFullName = (
				this.mainStore.getEventParticipationById(participationId) as EventParticipation
			).user.full_name;
		},
		async dispatchAssessmentUpdate() {
			this.dispatchingCall = true;
			try {
				await this.mainStore.partialUpdateEventParticipationSlot({
					courseId: this.courseId,
					eventId: this.eventId,
					participationId: this.editingParticipationId,
					slotId: (this.editingSlot as EventParticipationSlot).id,
					changes: {
						score: this.editingSlotDirty?.score,
						comment: this.editingSlotDirty?.comment,
					},
					mutate: true,
				});
				await this.mainStore.getEventParticipation({
					courseId: this.courseId,
					eventId: this.eventId,
					participationId: this.editingParticipationId,
				});
				this.hideDialog();
				this.metaStore.showSuccessFeedback();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}
		},
		async onCloseExam() {
			const dialogData: DialogData = {
				title: _("course_events.close_exam_for_everyone_title"),
				text:
					_("course_events.close_exam_for_everyone_body_1_alt") +
					" " +
					this.event.name +
					" " +
					_("course_events.close_exam_for_everyone_body_2") +
					"?",
				yesText: _("course_events.close_for_everyone"),
				noText: _("dialog.default_cancel_text"),
			};
			this.blockingDialogData = dialogData;

			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.withLoading(
				async () =>
					await this.mainStore.partialUpdateEvent({
						courseId: this.courseId,
						eventId: this.event.id,
						mutate: true,
						changes: {
							state: EventState.CLOSED,
							users_allowed_past_closure: [],
						},
					}),
				setErrorNotification,
				() => this.metaStore.showSuccessFeedback(),
			);
			this.showBlockingDialog = false;
		},
		async onCloseSelectedExams() {
			const applicableParticipationsIds = this.selectedParticipations
				.filter(p => this.bulkActions.close.applicable(p))
				.map(p => p.id);

			const dialogData: DialogData = {
				title: _("event_monitor.close_for_selected"),
				yesText:
					_("misc.close") +
					" " +
					applicableParticipationsIds.length +
					" " +
					_("misc.exam", applicableParticipationsIds.length),
				noText: _("dialog.default_cancel_text"),
				text:
					_("event_monitor.close_for_selected_text_1") +
					" " +
					applicableParticipationsIds.length +
					" " +
					_("misc.participant", applicableParticipationsIds.length) +
					".",
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.dispatchParticipationsUpdate(applicableParticipationsIds, {
				state: EventParticipationState.CLOSED_BY_TEACHER,
			});

			// TODO extract
			if (this.resultsMode && !(VISITED_INSIGHTS_TOUR_KEY in localStorage)) {
				setTimeout(() => (this.$tours as any)["examInsightsTour"].start(), 1000);
				localStorage.setItem(VISITED_INSIGHTS_TOUR_KEY, "true");
			}
		},
		async onOpenSelectedExams() {
			const applicableParticipationsIds = this.selectedParticipations
				.filter(p => this.bulkActions.reOpen.applicable(p))
				.map(p => p.id);

			const dialogData: DialogData = {
				title: _("event_monitor.open_for_selected"),
				yesText:
					_("misc.reopen") +
					" " +
					applicableParticipationsIds.length +
					" " +
					_("misc.exam", applicableParticipationsIds.length),
				noText: _("dialog.default_cancel_text"),
				onYes: this.openExams,
				text:
					_("event_monitor.open_for_selected_text") +
					" " +
					applicableParticipationsIds.length +
					" " +
					_("misc.participant", applicableParticipationsIds.length) +
					".",
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.dispatchParticipationsUpdate(applicableParticipationsIds, {
				state: EventParticipationState.IN_PROGRESS,
			});
		},
		async dispatchParticipationsUpdate(
			participationIds: string[],
			changes: Partial<EventParticipation>,
			fireIntegrationEvent?: boolean,
		) {
			/**
			 * Generic method to update multiple participations
			 * at once and show feedback/error
			 */
			this.dispatchingCall = true;

			try {
				await this.mainStore.bulkPartialUpdateEventParticipation({
					courseId: this.courseId,
					eventId: this.eventId,
					participationIds,
					changes,
					fireIntegrationEvent,
				});
				this.showBlockingDialog = false;
				this.metaStore.showSuccessFeedback();
				this.deselectAllRows();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
				throw e;
			} finally {
				this.dispatchingCall = false;
			}
		},
		async onPublishResults() {
			const dialogData: DialogData = {
				//onYes: this.publishResults,
				yesText: _("event_results.publish"),
				noText: _("dialog.default_cancel_text"),
				text: _("event_results.publish_confirm_text"),
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			// TODO handle blocking dialog
			try {
				await this.dispatchParticipationsUpdate(
					// TODO if you select all you might mistakenly publish results for unassessed participations
					this.selectedParticipationsIds,
					{
						visibility: AssessmentVisibility.PUBLISHED,
					},
					this.publishToClassroom,
				);
			} catch {
				// ! this is a hack to make the dialog keep working - without this it wouldn't work because the promise has already been resolved
				await this.onPublishResults();
			}
		},
		async onUndoParticipationTurnIn() {
			const applicableParticipationsIds = this.selectedParticipations
				.filter(p => this.bulkActions.undoTurnIn.applicable(p))
				.map(p => p.id);
			const dialogData: DialogData = {
				title: "",
				text:
					_("event_monitor.un_turn_in_text", applicableParticipationsIds.length) +
					(this.event.exercises_shown_at_a_time === 1
						? " " +
						  _(
								"event_monitor.student_will_be_brought_back_to_first_slot",
								applicableParticipationsIds.length,
						  )
						: ""),
				warning: false,
				noText: _("dialog.default_no_text"),
				yesText: _("dialog.default_yes_text"),
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.dispatchParticipationsUpdate(applicableParticipationsIds, {
				state: EventParticipationState.IN_PROGRESS,
				current_slot_cursor: 0,
			});
		},
		async onSyncGradesWithClassroom() {
			const dialogData: DialogData = {
				title: _("integrations.classroom.sync_grades_with_classroom"),
				text: _("integrations.classroom.sync_grades_with_classroom_description"),
				warning: false,
				noText: _("dialog.default_cancel_text"),
				yesText: _("integrations.classroom.sync"),
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			try {
				this.dispatchingCall = true;
				await this.googleIntegrationStore.syncExamGrades(this.eventId);
				this.showBlockingDialog = false;
				this.metaStore.showSuccessFeedback();
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}
		},

		setSortingOption(index: number) {
			this.selectedSortingOption = index;
			this.sortingOptionsExpanded = false;
		},

		hideDialog() {
			this.editingSlot = null;
			this.editingSlotDirty = null;
			this.editingFullName = "";
			this.editingParticipationId = "";
			this.selectedParticipationsIds = [];
			this.showAssessmentEditorDialog = false;
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore, useGoogleIntegrationsStore),
		selectedParticipations() {
			return this.mainStore.eventParticipations.filter(p =>
				this.selectedParticipationsIds.includes(p.id),
			);
		},
		sortingOptions() {
			const options: {
				label: string;
				sortFn: (p1: EventParticipation, p2: EventParticipation) => number;
			}[] = [
				{
					label: _("event_monitor.sort_options.alphabetical"),
					sortFn: (p1, p2) => {
						const lastName1 = p1.user.last_name;
						const lastName2 = p2.user.last_name;
						console.log({ lastName1, lastName2 });
						if (lastName1 > lastName2) {
							return 1;
						}
						if (lastName1 < lastName2) {
							return -1;
						}
						return 0;
					},
				},
				{
					label: _("event_monitor.sort_options.alphabetical_reverse"),
					sortFn: (p1, p2) => {
						const lastName1 = p1.user.last_name;
						const lastName2 = p2.user.last_name;
						if (lastName1 > lastName2) {
							return -1;
						}
						if (lastName1 < lastName2) {
							return 1;
						}
						return 0;
					},
				},
				{
					label: _("event_monitor.sort_options.begin_timestamp"),
					sortFn: (p1, p2) => {
						const begin1 = p1.begin_timestamp;
						const begin2 = p2.begin_timestamp;
						return new Date(begin1).getTime() - new Date(begin2).getTime();
					},
				},
				{
					label: _("event_monitor.sort_options.begin_timestamp_reverse"),
					sortFn: (p1, p2) => {
						const begin1 = p1.begin_timestamp;
						const begin2 = p2.begin_timestamp;
						return new Date(begin2).getTime() - new Date(begin1).getTime();
					},
				},
				// { label: _("event_monitor.sort_options.progress") },
				// { label: _("event_monitor.sort_options.progress_inverse") },
			];
			return options;
		},
		bulkActions() {
			const actions = {
				undoTurnIn: {
					applicable: (p: EventParticipation) =>
						p.state === EventParticipationState.TURNED_IN,
					method: this.onUndoParticipationTurnIn,
					label: _("event_monitor.undo_turn_in"),
				},
				close: {
					applicable: (p: EventParticipation) =>
						p.state === EventParticipationState.IN_PROGRESS,
					method: this.onCloseSelectedExams,
					label: _("event_monitor.close_for_selected"),
				},
				reOpen: {
					applicable: (p: EventParticipation) =>
						p.state === EventParticipationState.CLOSED_BY_TEACHER,
					method: this.onOpenSelectedExams,
					label: _("event_monitor.reopen_for_selected"),
				},
			};

			return actions;
		},
		showClassroomIntegrationSwitch() {
			// TODO refactor
			return (
				this.googleClassroomCourseWorkTwin !== null &&
				(this.blockingDialogData?.text ?? "") === _("event_results.publish_confirm_text")
			);
		},
		dialogData(): DialogData {
			let ret = {} as DialogData;
			const defaultData = {
				onNo: this.hideDialog,
				noText: _("dialog.default_cancel_text"),
			} as DialogData;

			// TODO handle this
			if (this.dispatchingCall) {
				ret.disableOk = true;
				ret.yesText = _("misc.wait");
			}

			return { ...defaultData, ...ret };
		},
		event() {
			return this.mainStore.getEventById(this.eventId);
		},
		resultsMode() {
			return this.event.state === EventState.CLOSED;
		},
		participantCount() {
			return this.mainStore.eventParticipations.length;
		},
		turnedInCount() {
			return this.mainStore.eventParticipations.filter(
				(p: EventParticipation) => p.state === EventParticipationState.TURNED_IN,
			).length;
		},
		averageProgress() {
			return getParticipationsAverageProgress(
				this.mainStore.eventParticipations,
				this.event,
			);
		},
		thereAreUnpublishedAssessments() {
			return this.mainStore.eventParticipations.some(
				p => p.visibility != AssessmentVisibility.PUBLISHED,
			);
		},
		participationPreviewColumns() {
			return getEventParticipationMonitorHeaders(
				this.resultsMode,
				this.mainStore.eventParticipations,
			);
		},
		filteredParticipationsData() {
			const data = this.participationsData as any as EventParticipation[]; // TODO refactor
			return data
				.filter(d => userMatchesSearch(this.searchText, d.user))
				.sort(this.sortingOptions[this.selectedSortingOption].sortFn);
		},
		participationsData() {
			return this.mainStore.eventParticipations.map((p: EventParticipation) => {
				const ret = {
					id: p.id,
					email: p.user?.email,
					mat: p.user?.mat,
					currentSlotCursor: p.current_slot_cursor,
					course: p.user?.course,
					fullName: p.user?.full_name,
					state: this.resultsMode ? p.assessment_progress : p.state,
					visibility: p.visibility,
					score: p.score ?? "",
					user: p.user,
					begin_timestamp: p.begin_timestamp,
				} as Record<string, unknown>;
				p.slots.forEach(
					s => (ret["slot-" + ((s.slot_number as number) + 1)] = s), //s.score ?? '-')
				);
				return ret;
			});
		},
	},
});
