import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["min", "max", "disabled", "id"]
const _hoisted_2 = { class: "absolute bottom-1.5 origin-0 -z-1 w-full" }
const _hoisted_3 = {
  key: 0,
  class: "absolute bottom-0.5 right-1.5 -z-1"
}
const _hoisted_4 = {
  key: 1,
  class: "absolute z-10 -mb-1.25px opacity-70 bottom-2 text-muted right-7"
}
const _hoisted_5 = {
  key: 2,
  style: {"bottom":"3.2px"},
  class: "absolute left-1.5 -z-1"
}
const _hoisted_6 = {
  key: 3,
  class: "hidden text-sm font-light text-danger-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
			'border-danger-dark focus-within:border-danger-dark': _ctx.$slots.errors,
			'py-1.5 px-2': !_ctx.small,
			'py-0.5 px-1 w-20': _ctx.small,
			'bg-light': _ctx.filled,
		}, "relative z-10 duration-300 border-b-2 rounded-t-sm light-input darken-on-hover transition-border focus-within:border-primary"])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "number",
      min: _ctx.min,
      max: _ctx.max,
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('blur'))),
      disabled: _ctx.disabled,
      placeholder: " ",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
      id: _ctx.elementId,
      class: _normalizeClass(["z-10 block w-full bg-transparent appearance-none floating-label focus:outline-none", {
				'pl-5': _ctx.leftIcon.length > 0,
				'floating-label-error': _ctx.$slots.errors?.(),
			}])
    }, null, 42, _hoisted_1), [
      [
        _vModelText,
        _ctx.proxyModelValue,
        void 0,
        { number: true }
      ]
    ]),
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.rightIcon.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-base text-muted", [_ctx.iconFilled ? 'material-icons' : 'material-icons-outlined']])
          }, _toDisplayString(_ctx.rightIcon), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.rightHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "rightHint")
        ]))
      : _createCommentVNode("", true),
    (_ctx.leftIcon.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", {
            class: _normalizeClass(["text-base text-muted", [_ctx.iconFilled ? 'material-icons' : 'material-icons-outlined']])
          }, _toDisplayString(_ctx.leftIcon), 3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.errors?.())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "errors")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}