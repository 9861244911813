
import { blockingDialogMixin, courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent, PropType } from "@vue/runtime-core";
import {
	forceFileDownload,
	getColorFromString,
	logAnalyticsEvent,
	roundToTwoDecimals,
	setErrorNotification,
} from "@/utils";
import DataTable from "@/components/ui/DataTable.vue";
import { EventSearchFilter, getCourseParticipationReport } from "@/api";
import {
	EventState,
	EventType,
	User,
	Event,
	EventParticipation,
	CourseExamParticipationReport,
	userMatchesSearch,
} from "@/models";
import { getCourseInsightsHeaders } from "@/const";
import CheckboxGroup from "@/components/ui/CheckboxGroup.vue";
import { SelectableOption } from "@/interfaces";
import { getTranslatedString as _ } from "@/i18n";
import { normalizeOptionalStringContainingNumber } from "@/api/utils";
import StudentCard from "@/components/shared/StudentCard.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { getCourseExamParticipationsReportAsCsv } from "@/reports/courseExamsParticipations";
import Btn from "@/components/ui/Btn.vue";
import Tabs from "@/components/ui/Tabs.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Dialog from "@/components/ui/Dialog.vue";
import UserPicker from "@/components/shared/UserPicker.vue";
import { useMetaStore } from "@/stores/metaStore";
import { useGoogleIntegrationsStore } from "../../integrations/stores/googleIntegrationsStore";
import { GoogleClassroomCourseTwin } from "../../integrations/classroom/interfaces";
import StudentRenderer from "@/components/datatable/StudentRenderer.vue";
import CourseInsightsExamParticipationRenderer from "../../components/datatable/CourseInsightsExamParticipationRenderer.vue";
import CourseInsightsExamHeaderRenderer from "../../components/datatable/CourseInsightsExamHeaderRenderer.vue";
import DropdownMenu from "../../components/ui/DropdownMenu.vue";
import Dropdown from "../../components/ui/Dropdown.vue";
import NumberInput from "../../components/ui/NumberInput.vue";
import Spinner from "../../components/ui/Spinner.vue";

interface ExamsTableRow {
	user: User;
	scoreAverage: number;
}

export default defineComponent({
	name: "CourseInsights",
	mixins: [courseIdMixin, loadingMixin, blockingDialogMixin],
	props: {},
	components: {
		DataTable,
		CheckboxGroup,
		Btn,
		StudentCard,
		Tabs,
		TextInput,
		Dialog,
		UserPicker,
		// eslint-disable-next-line vue/no-unused-components
		StudentRenderer,
		// eslint-disable-next-line vue/no-unused-components
		CourseInsightsExamParticipationRenderer,
		// eslint-disable-next-line vue/no-unused-components
		CourseInsightsExamHeaderRenderer,
		DropdownMenu,
		Dropdown,
		NumberInput,
		Spinner,
	},
	beforeUnmount() {
		if (this.fetchUserPollingHandle) {
			clearInterval(this.fetchUserPollingHandle);
		}
	},
	watch: {
		viewMode(newVal) {
			logAnalyticsEvent("changeCourseInsightsViewMode", {
				courseId: this.courseId,
				to: newVal,
			});
			window.location.hash = newVal;
		},
		usersToEnroll: {
			handler(newVal) {
				// this will enable the `unsavedChanges` getter which will prompt
				// user for confirmation when leaving the window
				this.metaStore.saving = newVal.ids.length > 0 || newVal.emails.length > 0;
			},
			deep: true,
		},
		examsViewSelectedFilterOption(newVal) {
			if (newVal !== "no_filter") {
				this.$nextTick(() =>
					document.getElementById("grade-filter-value-input")?.focus(),
				);
			} else {
				this.examsViewAverageGradeFilterValue = 0;
			}
		},
	},
	async created() {
		const hash = window.location.hash.replace("#", "");
		// check for invalid hash
		if (["students", "exams"].includes(hash)) {
			this.viewMode = hash as "students" | "exams";
		}

		await this.withLoading(async () => {
			await Promise.all([this.fetchUsers(), this.fetchExams()]);
		});

		this.googleClassroomCourseTwin = await this.googleIntegrationStore.getCourseTwin(
			this.courseId,
		);

		await this.withLocalLoading(async () => {
			// initially enable all exams in the table
			this.selectedExamsIds = this.mainStore.exams.map(e => e.id);
			this.participations = await getCourseParticipationReport(this.courseId);
		});

		logAnalyticsEvent("viewedCourseStats", { courseId: this.courseId });
	},
	data() {
		return {
			loadingEnrolledStudents: false,
			examSelectionExpanded: false,
			loadingExams: false,
			gridApi: null as any,
			columnApi: null as any,
			participations: {} as CourseExamParticipationReport,
			selectedExamsIds: [] as string[],
			viewMode: "students" as "exams" | "students",
			downloadingReport: false,
			usersViewSearchText: "",
			usersToEnroll: { ids: [], emails: [] } as {
				ids: string[];
				emails: string[];
			},
			enrollingUsers: false,
			googleClassroomCourseTwin: null as null | GoogleClassroomCourseTwin,
			showClassroomSyncDialog: false,
			syncingClassroomRoster: false,
			fetchUserPollingHandle: null as null | number,
			// filtering on exam view
			examsViewSearchText: "",
			examsViewSelectedFilterOption: "no_filter",
			examsViewAverageGradeFilterValue: 0,
			examsViewSelectedSortingOption: 0,
			examsViewSortingOptionsExpanded: false,
		};
	},
	methods: {
		getRowId(data: any) {
			return data.id;
		},
		async onSyncClassroomRoster() {
			try {
				this.syncingClassroomRoster = true;
				await this.googleIntegrationStore.syncCourseRoster(this.courseId);
				this.showClassroomSyncDialog = false;
				this.metaStore.showSuccessFeedback(
					_("integrations.classroom.roster_sync_scheduled"),
					5000,
				);
				this.fetchUserPollingHandle = setInterval(() => {
					this.fetchUsers();
				}, 10_000);
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.syncingClassroomRoster = false;
			}
		},
		async fetchUsers() {
			this.loadingEnrolledStudents = true;
			try {
				await Promise.all([
					this.mainStore.getCourseEnrolledUsers({ courseId: this.courseId }),
					this.mainStore.getCourseActiveUsers({ courseId: this.courseId }),
				]);
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingEnrolledStudents = false;
			}
		},
		async fetchExams() {
			this.loadingExams = true;
			try {
				await this.mainStore.getEvents({
					courseId: this.courseId,
					filters: {
						event_type: EventType.EXAM,
					} as EventSearchFilter,
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingExams = false;
			}
		},
		async onEnrollUsers() {
			this.showBlockingDialog = true;
			const choice = await this.getBlockingBinaryDialogChoice();
			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}
			try {
				this.enrollingUsers = true;
				await this.mainStore.enrollUsersInCourse({
					courseId: this.courseId,
					userIds: this.usersToEnroll.ids,
					emails: this.usersToEnroll.emails,
				});
				// re-fetch enrolled users to include new ones
				await this.mainStore.getCourseEnrolledUsers({ courseId: this.courseId });
				// cleanup
				this.metaStore.showSuccessFeedback();
				this.usersToEnroll = {
					ids: [],
					emails: [],
				};
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.enrollingUsers = false;
				this.showBlockingDialog = false;
			}
		},
		downloadReport() {
			this.downloadingReport = true;
			try {
				const filteredSortedUsers = this.tableData.map((row: ExamsTableRow) => ({
					...row.user,
					score_average: row.scoreAverage,
				}));
				const content = getCourseExamParticipationsReportAsCsv(
					this.participations,
					filteredSortedUsers,
					this.selectedExams,
				).replace(/(\\r)?\\n/g, "\n");
				forceFileDownload(
					{
						data: content,
					},
					this.currentCourse.name + "_report.csv",
				);
			} catch (e) {
				setErrorNotification(e);
			} finally {
				setTimeout(() => (this.downloadingReport = false), 150);
			}
		},
		// exams view sorting & filtering
		setSortingOption(index: number) {
			this.examsViewSelectedSortingOption = index;
			this.examsViewSortingOptionsExpanded = false;
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore, useGoogleIntegrationsStore),
		// bookkeeping
		classroomIntegrationActive() {
			return this.googleClassroomCourseTwin !== null;
		},
		viewModesAsOptions(): SelectableOption[] {
			return [
				{
					value: "students",
					icons: ["people"],
					content: _("course_insights.enrolled_students"),
				},
				{
					value: "exams",
					icons: ["table_chart"],
					content: _("course_insights.exams_stats"),
				},
			];
		},
		// users view & enrollment
		filteredEnrolledUsers() {
			const enrolledUsers = this.mainStore.enrolledUsers;
			return enrolledUsers.filter(u => {
				return userMatchesSearch(this.usersViewSearchText, u);
			});
		},
		usersToEnrollCount() {
			return this.usersToEnroll.emails.length + this.usersToEnroll.ids.length;
		},
		usersAsOptions(): SelectableOption[] {
			return this.mainStore.paginatedUsers.data.map(u => ({
				value: u.id,
				content: u.full_name,
				data: u,
			}));
		},
		// exams view data, sorting & filtering
		examViewFilteringOptions(): SelectableOption[] {
			return [
				{
					value: "no_filter",
					content: _("course_insights.no_filter"),
					data: () => true,
				},
				{
					value: "average_greater_than",
					content: _("course_insights.filter_average_greater_than"),
					data: (row: ExamsTableRow) =>
						row.scoreAverage >= (this.examsViewAverageGradeFilterValue ?? 0),
				},
				{
					value: "average_less_than",
					content: _("course_insights.filter_average_less_than"),
					data: (row: ExamsTableRow) =>
						row.scoreAverage <= (this.examsViewAverageGradeFilterValue ?? 0),
				},
			];
		},
		examsViewSortingOptions() {
			const options: {
				label: string;
				sortFn: (r1: ExamsTableRow, r2: ExamsTableRow) => number;
			}[] = [
				{
					label: _("event_monitor.sort_options.alphabetical"),
					sortFn: (r1, r2) => {
						const lastName1 = r1.user.last_name;
						const lastName2 = r2.user.last_name;
						if (lastName1 > lastName2) {
							return 1;
						}
						if (lastName1 < lastName2) {
							return -1;
						}
						return 0;
					},
				},
				{
					label: _("event_monitor.sort_options.alphabetical_reverse"),
					sortFn: (r1, r2) => {
						const lastName1 = r1.user.last_name;
						const lastName2 = r2.user.last_name;
						if (lastName1 > lastName2) {
							return -1;
						}
						if (lastName1 < lastName2) {
							return 1;
						}
						return 0;
					},
				},
				{
					label: _("event_monitor.sort_options.average_increasing"),
					sortFn: (r1, r2) => {
						const average1 = r1.scoreAverage;
						const average2 = r2.scoreAverage;
						if (average1 > average2) {
							return 1;
						}
						if (average1 < average2) {
							return -1;
						}
						return 0;
					},
				},
				{
					label: _("event_monitor.sort_options.average_decreasing"),
					sortFn: (r1, r2) => {
						const average1 = r1.scoreAverage;
						const average2 = r2.scoreAverage;
						if (average1 > average2) {
							return -1;
						}
						if (average1 < average2) {
							return 1;
						}
						return 0;
					},
				},
			];
			return options;
		},
		rawTableData() {
			if (this.loading) {
				return [];
			}
			const filteredUsers = this.activeUsersForSelectedExams;
			const exams = this.mainStore.exams;

			const getScoreSumFn = (u: User) =>
				Math.round(
					Object.entries(this.participations)
						.filter(([eventId]) =>
							this.selectedExamsIds.map(i => String(i)).includes(String(eventId)),
						)
						.map(([, participations]) => participations)
						.flat()
						.filter(p => p.user == u.id)
						.map(p => parseFloat(p.score ?? "0"))
						.reduce((a, b) => a + b, 0) * 100,
				) / 100;

			return filteredUsers.map(u => ({
				user: u, // this isn't used directly by the table, but it's useful for filtering & sorting
				id: u.id,
				email: u.email,
				fullName: u.full_name,
				student: u,
				mat: u.mat,
				course: u.course,
				...exams.reduce((acc, e) => {
					const participation = this.participations[e.id]?.find(p => p.user == u.id);
					const score = normalizeOptionalStringContainingNumber(participation?.score);
					acc["exam_" + e.id] = {
						id: participation?.id,
						examId: e.id,
						score,
					};
					return acc;
				}, {} as any),
				score_sum: getScoreSumFn(u),
				scoreAverage: roundToTwoDecimals(
					getScoreSumFn(u) /
						this.selectedExams.filter(
							// only consider exams the user has participated in
							e =>
								(this.participations[e.id] ?? []).findIndex(p => p.user == u.id) !== -1,
						).length,
				),
			}));
		},
		tableData() {
			return this.rawTableData
				.filter(
					row =>
						// if search text is provided, only show users that match it
						(!this.examsViewSearchText.trim() ||
							userMatchesSearch(this.examsViewSearchText, row.user)) &&
						// if filter is provided, only show users that match it
						this.examViewFilteringOptions
							.find(o => o.value === this.examsViewSelectedFilterOption)!
							// the filter function is stored in the `data` property of the filtering option
							.data(row),
				)
				.sort(this.examsViewSortingOptions[this.examsViewSelectedSortingOption].sortFn);
		},
		examsAsSelectableOptions(): SelectableOption[] {
			return (
				[...this.mainStore.exams]
					// TODO use moment
					.sort((a, b) =>
						new Date(b.begin_timestamp ?? "") < new Date(a.begin_timestamp ?? "")
							? 1
							: -1,
					)
					.map(e => ({
						value: e.id,
						content:
							e.name.trim().length > 0 ? e.name.trim() : _("event_preview.unnamed_event"),
					}))
			);
		},
		activeUsersForSelectedExams() {
			if (this.loading) {
				return [];
			}
			const users = this.mainStore.activeUsers;
			return users.filter(u =>
				this.selectedExams.some(
					e =>
						typeof (this.participations[e.id] ?? []).find(p => p.user == u.id) !==
						"undefined",
				),
			);
		},
		closedExamIds() {
			return this.mainStore.exams
				.filter(e => e.state === EventState.CLOSED)
				.map(e => e.id);
		},
		selectedExams(): Event[] {
			return this.mainStore.exams.filter(e =>
				this.selectedExamsIds.map(i => String(i)).includes(String(e.id)),
			);
		},
		tableHeaders() {
			// TODO sort exams by increasing begin timestamp
			// TODO add ability to select which exams to show
			return getCourseInsightsHeaders(this.selectedExams, this.examsColors);
		},
		examsColors(): Record<string, string> {
			return this.mainStore.exams.reduce((acc, e) => {
				acc[e.id] = getColorFromString(e.name);
				return acc;
			}, {} as Record<string, string>);
		},
	},
});
