
import { courseIdMixin, eventIdMixin } from "@/mixins";
import { EventParticipation, User } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Avatar from "../ui/Avatar.vue";
import Btn from "../ui/Btn.vue";
export default defineComponent({
	name: "StudentRenderer",
	props: {
		params: {
			type: Object as PropType<{
				value: User;
			}>,
			required: true,
		},
	},
	mixins: [eventIdMixin, courseIdMixin],
	methods: {},
	computed: {},
	components: {
		// Btn,
		Avatar,
	},
});
