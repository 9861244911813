
import GoogleScopeChecker from "@/integrations/components/GoogleScopeChecker.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { CLASSROOM_STUDENT_SCOPES } from "../const";
import { mapStores } from "pinia";
import { useMetaStore } from "../../../stores/metaStore";
import ArticleHandle from "../../../components/shared/HelpCenter/ArticleHandle.vue";
import HelpCenter from "../../../components/shared/HelpCenter/HelpCenter.vue";
import { useGoogleIntegrationsStore } from "../../stores/googleIntegrationsStore";
export default defineComponent({
	name: "StudentScopesBanner",
	props: {},
	data() {
		return {
			CLASSROOM_STUDENT_SCOPES,
			shakeBanner: false,
		};
	},
	methods: {
		onScopesOk() {
			this.googleIntegrationStore.studentScopesBannerVisible = false;
		},
		onScopesCheckFailed() {
			this.googleIntegrationStore.studentScopesBannerVisible = true;
		},
	},
	computed: {
		...mapStores(useMetaStore, useGoogleIntegrationsStore),
		showMissingScopesBanner(): boolean {
			return this.googleIntegrationStore.studentScopesBannerVisible;
		},
	},
	components: { GoogleScopeChecker, ArticleHandle, HelpCenter },
});
