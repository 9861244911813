
import { defineComponent } from "@vue/runtime-core";
import CourseListItem from "@/components/shared/CourseListItem.vue";

import { blockingDialogMixin, loadingMixin } from "@/mixins";
import CourseListItemSkeleton from "@/components/ui/skeletons/CourseListItemSkeleton.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import Btn from "@/components/ui/Btn.vue";
import { Course } from "@/models";
import { getBlankCourseSearchFilters } from "@/api/utils";
import { CourseSearchFilter } from "@/api/interfaces";
import CourseSearchFilters from "@/components/shared/CourseSearchFilters.vue";
import { demoCourseTourSteps, tourOptions } from "@/const";
import { isDemoMode, setErrorNotification } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import Dialog from "@/components/ui/Dialog.vue";
import StudentScopesBanner from "@/integrations/classroom/components/StudentScopesBanner.vue";

const DEMO_COURSES_TOUR_KEY = "courses_tour_taken";

export default defineComponent({
	name: "CourseList",
	mixins: [loadingMixin, blockingDialogMixin],
	components: {
		CourseListItem,
		CourseListItemSkeleton,
		NumberInput,
		Btn,
		CourseSearchFilters,
		Dialog,
		StudentScopesBanner,
	},
	async created() {
		await this.withFirstLoading(async () => this.mainStore.getCourses());
		this.searchFilters.withPrivileges = this.metaStore.user.is_teacher;
		this.searchFilters.hidden = this.metaStore.user.is_teacher;

		if (isDemoMode() && !(DEMO_COURSES_TOUR_KEY in localStorage)) {
			setTimeout(() => ((this as any).$tours["demoCourseTour"] as any).start(), 50);
			localStorage.setItem(DEMO_COURSES_TOUR_KEY, "true");
		}
	},
	data() {
		return {
			dirtyMat: "",
			searchFilters: getBlankCourseSearchFilters(),
			demoCourseTourSteps,
			tourOptions,
			enrollmentDialogMode: null as null | "enroll" | "unenroll",
			selectedCourseForEnrollment: null as Course | null,
			enrollmentLoading: false,
		};
	},
	watch: {
		// searchFilters: {
		//   handler(newVal) {},
		//   deep: true,
		// },
	},
	methods: {
		async onEnrollChange(course: Course, mode: "enroll" | "unenroll") {
			this.enrollmentDialogMode = mode;
			this.selectedCourseForEnrollment = course;
			const choice = await this.getBlockingBinaryDialogChoice();
			if (!choice) {
				this.showBlockingDialog = false;
				this.selectedCourseForEnrollment = null;
				this.enrollmentDialogMode = null;
				return;
			}
			try {
				this.enrollmentLoading = true;
				await (this.enrollmentDialogMode === "enroll"
					? this.mainStore.selfEnrollInCourse
					: this.mainStore.selfUnenrollInCourse)({ courseId: course.id });
				this.metaStore.showSuccessFeedback();
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.showBlockingDialog = false;
				this.selectedCourseForEnrollment = null;
				this.enrollmentLoading = false;
				this.enrollmentDialogMode = null;
			}
		},
		async onSaveMat() {
			await this.withLocalLoading(async () =>
				this.metaStore.patchUser({
					userId: this.metaStore.user.id,
					changes: { mat: this.dirtyMat },
				}),
			);
			this.metaStore.showSuccessFeedback();
		},
		async onCourseToggleFavorite(course: Course) {
			const remove = !!course.bookmarked;
			await this.mainStore.bookmarkCourse({ courseId: course.id, remove });
			// TODO if adding a bookmark and courses moves out of view, show some kind of feedback
		},
		sortCourses(courses: Course[]) {
			return courses.sort((a, b) => {
				if (a.creator?.id == this.metaStore.user.id) {
					// courses the user is creator of go first
					return b.creator?.id == this.metaStore.user.id ? 0 : -1;
				}
				return b.creator?.id == this.metaStore.user.id
					? 1
					: // then come the courses that user has privileges over
					  (b.privileges?.length ?? 0) - (a.privileges?.length ?? 0) ||
							// as a last resort, sort by id
							(a.id < b.id ? -1 : 1);
			});
		},
		// testExtraScopes() {
		// 	const SCOPES = [
		// 		"https://www.googleapis.com/auth/classroom.courses.readonly",
		// 		"https://www.googleapis.com/auth/classroom.announcements",
		// 		"https://www.googleapis.com/auth/classroom.courseworkmaterials",
		// 		"https://www.googleapis.com/auth/classroom.coursework.students",
		// 		"https://www.googleapis.com/auth/classroom.rosters",
		// 		"https://www.googleapis.com/auth/classroom.profile.emails",
		// 	];
		// 	var options = new (window as any).gapi.auth2.SigninOptionsBuilder({
		// 		scope: "email https://www.googleapis.com/auth/drive " + SCOPES.join(" "),
		// 	});

		// 	const googleUser = this.$gAuth.instance.currentUser.get(); //.get(); //(window as any).gapi.auth2.currentUser.get();
		// 	console.log("CURRENT", this.$gAuth.instance.currentUser.get());
		// 	googleUser.grant(options).then(
		// 		function (success) {
		// 			console.log(JSON.stringify({ message: "success", value: success }));
		// 		},
		// 		function (fail) {
		// 			alert(JSON.stringify({ message: "fail", value: fail }));
		// 		},
		// 	);
		// },
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		isDemoMode() {
			return isDemoMode();
		},
		coursesFiltered() {
			const filters = this.searchFilters;
			return this.coursesSorted.filter(
				c =>
					(filters.name.length === 0 ||
						c.name.toLowerCase().includes(filters.name.toLowerCase()) ||
						(c.creator?.full_name ?? "")
							.toLowerCase()
							.includes(filters.name.toLowerCase())) &&
					(!filters.withPrivileges || (c.privileges?.length ?? 0) > 0) &&
					(filters.hidden || !c.hidden),
			);
		},
		coursesSorted() {
			const bookmarkedEnrolledCourses = this.mainStore.courses.filter(
				c => c.bookmarked && c.enrolled,
			);
			const enrolledCourses = this.mainStore.courses.filter(
				c => c.enrolled && !c.bookmarked,
			);
			const bookmarkedCourses = this.mainStore.courses.filter(
				c => c.bookmarked && !c.enrolled,
			);
			const otherCourses = this.mainStore.courses.filter(
				c => !c.bookmarked && !c.enrolled,
			);
			return [
				...this.sortCourses(bookmarkedEnrolledCourses),
				...this.sortCourses(bookmarkedCourses),
				...this.sortCourses(enrolledCourses),
				...this.sortCourses(otherCourses),
			];
		},
	},
});
