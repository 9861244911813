
import { EventParticipation } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as assessmentStateIcons } from "@/assets/assessmentStateIcons";
import Btn from "../ui/Btn.vue";

export default defineComponent({
	name: "CourseInsightsExamParticipationRenderer",
	props: {
		params: {
			type: Object as PropType<{
				value: {
					id: string;
					examId: string;
					score: null | string | number;
				};
			}>,
			required: true,
		},
	},
	data() {
		return {};
	},
	methods: {},
	computed: {},
	components: { Btn },
});
