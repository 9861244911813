
import { Event, EventState } from "@/models";
import { defineComponent, inject, PropType } from "@vue/runtime-core";
import { icons as eventStateIcons } from "@/assets/eventStateIcons";
import { getTranslatedString as _ } from "@/i18n";
//import Card from "@/components/ui/Card.vue";
import Btn from "@/components/ui/Btn.vue";
import { getExamPermalink, getFormattedTimestamp } from "@/utils";
import Timestamp from "@/components/ui/Timestamp.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import { courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import IntegrationSwitch from "@/integrations/classroom/components/IntegrationSwitch.vue";
import { mapStores } from "pinia";
import { useGoogleIntegrationsStore } from "@/integrations/stores/googleIntegrationsStore";
import PublishedOnClassroom from "@/integrations/classroom/components/PublishedOnClassroom.vue";
import { GoogleClassroomCourseWorkTwin } from "@/integrations/classroom/interfaces";

export default defineComponent({
	components: {
		Btn,
		Timestamp,
		CopyToClipboard,
		IntegrationSwitch,
		PublishedOnClassroom,
	},
	props: {
		modelValue: {
			type: Object as PropType<Event>,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin, eventIdMixin],
	setup() {
		return {
			v$: inject("v$"),
		};
	},
	name: "EventStateEditor",
	async created() {
		this.showClassroomIntegrationSwitch =
			await this.googleIntegrationStore.isGoogleClassroomIntegrationActive(this.courseId);
		await this.checkForCourseworkTwin();
	},
	data() {
		return {
			shakeErrorBanner: false,
			publishToClassroom: true,
			showClassroomIntegrationSwitch: false,
			googleClassroomCourseWorkTwin: null as null | GoogleClassroomCourseWorkTwin,
		};
	},
	methods: {
		async checkForCourseworkTwin() {
			this.googleClassroomCourseWorkTwin =
				await this.googleIntegrationStore.getGoogleClassroomCourseWorkTwin(this.eventId);
		},
		emitUpdate(value: EventState) {
			this.$emit("update:modelValue", {
				value,
				fireIntegrationEvent: this.publishToClassroom,
			});
			// if we're changing the exam to PLANNED and there's an active integration
			// with Classroom, check whether the twin coursework was created successfully
			if (
				this.publishToClassroom &&
				this.showClassroomIntegrationSwitch &&
				value === EventState.PLANNED
			) {
				const handle = setInterval(async () => {
					await this.checkForCourseworkTwin();
					if (this.hasGoogleClassroomTwin) {
						clearInterval(handle);
					}
				}, 1000);
			}
		},
		onInvalidSubmission() {
			// make errors visible
			(this.v$ as any).$touch();

			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight,
			);
			// scroll to bottom of page to account for error messages appearing
			// in order to keep error banner in view
			this.$nextTick(() =>
				window.scrollTo(
					0,
					document.body.scrollHeight || document.documentElement.scrollHeight,
				),
			);
			this.shakeErrorBanner = true;
		},
		publish() {
			this.emitUpdate(EventState.PLANNED);
		},
		revertToDraft() {
			this.emitUpdate(EventState.DRAFT);
		},
	},
	computed: {
		...mapStores(useGoogleIntegrationsStore),
		eventStateOptions() {
			return (Object.keys(EventState) as unknown[] as EventState[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: eventStateIcons[key],
					value: key,
					content: _("event_states." + key),
					description: _("event_states_descriptions." + key),
				}));
		},
		hasGoogleClassroomTwin() {
			return this.googleClassroomCourseWorkTwin !== null;
		},
		isDraft() {
			return this.modelValue.state == EventState.DRAFT;
		},
		isPlanned() {
			return this.modelValue.state == EventState.PLANNED;
		},
		isOpen() {
			return this.modelValue.state == EventState.OPEN;
		},
		googleClassroomCourseTwin() {
			return this.googleIntegrationStore.courseTwins[this.courseId];
		},
		currentEventStateName() {
			return this.eventStateOptions[this.modelValue?.state]?.content?.toLowerCase();
		},
		currentEventStateDescription() {
			return this.eventStateOptions[this.modelValue?.state]?.description;
		},
		formattedTimestamp() {
			return getFormattedTimestamp(this.modelValue.begin_timestamp ?? "");
		},
		permalink(): string {
			return getExamPermalink(this.modelValue);
		},
		relevantErrors() {
			// remove the errors that are relative to event template rules as those
			// are displayed in the rule editor component
			return (
				(this.v$ as any).$errors
					// TODO find a less hacky way
					.filter(
						(e: { $uid: string }) =>
							![
								"rule_type-ruleTypeSet",
								"exercises-idBasedRulePopulated",
								"clauses-tagBasedRulePopulated",
								"satisfying-tagBasedRuleSatisfied",
							].includes(e.$uid.slice("modelValue".length + 1)),
					)
			);
		},
	},
});
