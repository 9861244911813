import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "px-2 ml-2 font-semibold rounded-full bg-primary bg-opacity-20 text-primary"
}
const _hoisted_4 = {
  key: 2,
  class: "md:static absolute top-1/2 left-1/2 md:translate-x-0 md:translate-y-0 transform -translate-x-1/2 -translate-y-1/2 md:shadow-none shadow-lg bg-light md:bg-transparent flex md:flex-row flex-col mt-2 w-full items-center md:space-x-2 space-y-2 md:space-y-0 md:py-0 py-2 md:px-0 px-2"
}
const _hoisted_5 = { class: "flex space-x-2 items-center" }
const _hoisted_6 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_7 = { class: "lg:block block md:hidden" }
const _hoisted_8 = { class: "flex items-center w-full md:w-max space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chipset = _resolveComponent("Chipset")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tagsAsSelectableOptions.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createBlock(_component_Chipset, {
          key: 1,
          options: _ctx.tagsAsSelectableOptions,
          modelValue: _ctx.proxyModelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
          allowMultiple: true,
          class: _normalizeClass({ 'opacity-30 select-none pointer-events-none': _ctx.editingTagId }),
          disabled: !!_ctx.editingTagId
        }, {
          default: _withCtx(({ optionValue }) => [
            (_ctx.tagsToRules[optionValue])
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  ((_ctx.tagsToRules[optionValue]?.amount ?? 0) > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.tagsToRules[optionValue].amount), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["options", "modelValue", "class", "disabled"])),
    (_ctx.editingTagId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("practice_template_editor.add_tag_1")), 1),
            _createVNode(_component_NumberInput, {
              min: 1,
              max: _ctx.editingTag?.public_exercises_not_seen ?? 100000000,
              class: "lg:w-28 w-32",
              label: _ctx.$t('practice_template_editor.amount'),
              modelValue: _ctx.editingTagDirtyAmount,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingTagDirtyAmount) = $event))
            }, {
              rightHint: _withCtx(() => [
                (_ctx.editingTag?.public_exercises_not_seen)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, " /" + _toDisplayString(_ctx.editingTag?.public_exercises_not_seen ?? ""), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["max", "label", "modelValue"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.$tc("practice_template_editor.add_tag_2", _ctx.editingTagDirtyAmount ?? 1)), 1)
          ]),
          _createVNode(_component_Tag, {
            tag: _ctx.editingTag,
            class: ""
          }, null, 8, ["tag"]),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("practice_template_editor.add_tag_3")), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Btn, {
              size: 'sm',
              class: "my-auto md:w-max w-full",
              disabled: _ctx.editingTagDirtyAmount < 1,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resolveTagSelectPromise(true)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialog.default_ok_text")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_Btn, {
              size: 'sm',
              class: "my-auto md:w-max w-full",
              outline: true,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resolveTagSelectPromise(false)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialog.default_cancel_text")), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}