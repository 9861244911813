import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.params.value.id)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        style: {"margin-top":"9px","line-height":"1"},
        class: "flex",
        target: "_blank",
        to: {
			name: 'ExamParticipationFull',
			params: { participationId: _ctx.params.value.id, examId: _ctx.params.value.examId },
		}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Btn, {
            variant: 'icon',
            outline: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.params.value.score), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}