import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center",
  style: {"margin-top":"13px"}
}
const _hoisted_2 = {
  class: "flex flex-col space-y-0.5 ml-3 mr-1.5",
  style: {"line-height":"1 !important"}
}
const _hoisted_3 = { class: "text-xs text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      style: {"line-height":"1 !important"},
      user: _ctx.params.value
    }, null, 8, ["user"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.params.value.full_name), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.params.value.email), 1)
    ])
  ]))
}