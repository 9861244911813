
import { defineComponent, PropType } from "@vue/runtime-core";
import { GoogleClassroomCourseData } from "@/integrations/classroom/interfaces";
import Btn from "@/components/ui/Btn.vue";
export default defineComponent({
	name: "GoogleClassroomCourseItem",
	props: {
		course: {
			type: Object as PropType<GoogleClassroomCourseData>,
			required: true,
		},
		selected: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: false,
		},
	},
	methods: {},
	computed: {},
	components: { Btn },
});
