
import { SelectableOption } from "@/interfaces";
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import { MDCCheckbox } from "@material/checkbox";

export default defineComponent({
	name: "CheckboxGroup",
	//props: ['options', 'modelValue', 'disabled'],
	props: {
		options: {
			type: Object as PropType<SelectableOption[]>,
			required: true,
		},
		modelValue: {
			type: Array as PropType<unknown[]>,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelClass: {
			type: String,
			default: "",
		},
		optionClass: {
			type: String,
			default: "",
		},
		useToggles: {
			type: Boolean,
			default: false,
		},
		usesCustomSlot: {
			type: Boolean,
			default: false,
		},
		itemClass: {
			type: String,
			default: "",
		},
	},
	// when disabled changes, update the checkboxes
	watch: {
		disabled: {
			handler() {
				this.$nextTick(() => {
					this.checkboxes.forEach(checkbox => {
						checkbox.disabled = this.disabled;
					});
				});
			},
			immediate: true,
		},
	},
	mounted() {
		this.options.map((option, i) => {
			const checkbox = new MDCCheckbox(
				document.getElementById(this.id + "-checkbox-" + i) as Element,
			);
			this.checkboxes.push(checkbox);

			checkbox.checked = this.modelValue.includes(option.value);
			checkbox.disabled = this.disabled;

			// attach event listener
			checkbox.listen("input", e => {
				console.log(e);
				this.$emit(
					"update:modelValue",
					(e.target as HTMLInputElement).checked
						? [...this.modelValue, option.value]
						: this.modelValue.filter(v => v !== option.value),
				);
			});
		});
	},
	data() {
		return {
			id: "a" + uuid4(),
			checkboxes: [] as any[],
		};
	},
	methods: {
		onToggleUpdate(event: unknown, option: SelectableOption) {
			if (event) {
				// new selection
				this.proxyModelValue = [...this.proxyModelValue, option.value];
			} else {
				// deselection
				this.proxyModelValue = this.proxyModelValue.filter(v => v !== option.value);
			}
		},
	},
	computed: {
		proxyModelValue: {
			get(): unknown[] {
				return this.modelValue;
			},
			set(val: SelectableOption[]) {
				this.$emit("update:modelValue", val);
			},
		},
	},
});
