
import { getCourseTopicNodes } from "@/api";
import Btn from "@/components/ui/Btn.vue";
import CloudSaveStatus from "@/components/ui/CloudSaveStatus.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import TextEditor from "@/components/ui/TextEditor.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { getTranslatedString as _ } from "@/i18n";
import { SelectableOption } from "@/interfaces";
import { courseIdMixin, savingMixin } from "@/mixins";
import {
	CourseTreeNodeType,
	FileNode as IFileNode,
	getBlankFileNode,
	getBlankPollNodeChoice,
	PollNode,
	PollNodeChoice,
	PollNodeState,
	TopicNode,
} from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { setErrorNotification } from "@/utils";
import { pollNodeValidation } from "@/validation/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";
import { mapStores } from "pinia";
import { nodeEditorProps } from "../shared";
export default defineComponent({
	name: "PollNodeEditor",
	props: {
		modelValue: {
			type: Object as PropType<PollNode>,
			required: true,
		},
		...nodeEditorProps,
	},
	mixins: [courseIdMixin, savingMixin],
	validations() {
		return {
			modelValue: pollNodeValidation,
		};
	},
	setup() {
		const v = useVuelidate();
		return { v$: v };
	},
	data() {
		return {
			PollNodeState,
			creatingAttachment: false,
			topics: [] as TopicNode[],
			loadingTopics: false,
			loadingChildren: false,
			editingChoice: null as null | PollNodeChoice,
			editingChoiceText: "",
			savingChoice: false,
		};
	},
	async created() {
		await this.mainStore.getCourseRootId({ courseId: this.courseId });
		await Promise.all([
			(async () => {
				this.loadingChildren = true;
				try {
					await this.mainStore.getCourseTreeNodeChildren({
						courseId: this.courseId,
						nodeId: this.modelValue.id,
						fromFirstPage: true,
					});
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.loadingChildren = false;
				}
			})(),
			(async () => {
				this.loadingTopics = true;
				try {
					this.topics = await getCourseTopicNodes(this.courseId);
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.loadingTopics = false;
				}
			})(),
		]);
	},
	methods: {
		// actions on choices
		onCreateChoice() {
			this.editingChoice = getBlankPollNodeChoice();
		},
		onEditChoice(choice: PollNodeChoice) {
			this.editingChoice = JSON.parse(JSON.stringify(choice));
		},
		async onSaveChoice() {
			if (!this.editingChoice) {
				throw new Error(
					"attempting to save editingChoice, which is " + this.editingChoice,
				);
			}
			if (this.editingChoice.text.trim().length === 0) {
				return;
			}
			try {
				this.savingChoice = false;
				if (!this.editingChoice.id) {
					console.log("Creating", this.editingChoice);
					await this.mainStore.createPollNodeChoice({
						courseId: this.courseId,
						nodeId: this.modelValue.id,
						choice: this.editingChoice,
					});
				} else {
					await this.mainStore.partialUpdatePollNodeChoice({
						courseId: this.courseId,
						nodeId: this.modelValue.id,
						choiceId: this.editingChoice.id,
						changes: this.editingChoice,
					});
				}
				this.editingChoice = null;
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.savingChoice = false;
			}
		},
		onDiscardChoice() {
			this.editingChoice = null;
		},
		async onDeleteChoice(choice: PollNodeChoice) {
			if (!confirm(_("course_tree.confirm_delete_poll_choice"))) {
				return;
			}
			await this.mainStore.deletePollNodeChoice({
				courseId: this.courseId,
				nodeId: this.modelValue.id,
				choiceId: choice.id,
			});
		},
		/** TODO this behavior is all shared with other editors, extract */
		async onNodeChange<K extends keyof PollNode>(key: any, value: any, save = false) {
			this.$emit("patchNode", { key, value, save });
		},
		onSave() {
			if (this.modelValue.state !== PollNodeState.DRAFT && this.v$.$invalid) {
				this.v$.$touch();
				return;
			}
			this.$emit("save");
		},
		onBlur() {
			this.$emit("blur");
		},
		async onParentChange(parentId: string) {
			await this.onNodeChange("parent_id", parentId);
		},
		async onPublish() {
			this.v$.$touch();
			if (this.v$.$errors.length === 0) {
				this.$emit("updateState", {
					newState: PollNodeState.OPEN,
					params: {},
				});
			}
		},
		/** end shared behavior */
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		// TODO refactor, duplicated with CourseTree
		topicsAsOptions(): SelectableOption[] {
			return [
				{
					value: this.mainStore.courseIdToTreeRootId[this.courseId],
					content: _("course_tree.no_topic"),
				},
				...this.topics.map(t => ({
					value: t.id,
					content: t.name,
				})),
			];
		},
		fileChildren(): IFileNode[] {
			return (
				this.mainStore.paginatedChildrenByNodeId[this.modelValue.id]?.data ?? []
			).filter(c => c.resourcetype === CourseTreeNodeType.FileNode) as IFileNode[];
		},
		// attachmentProxy: {
		// 	get() {
		// 		return [];
		// 	},
		// 	async set(val: any) {
		// 		await this.onCreateAttachment(val);
		// 	},
		// },
	},
	components: {
		//TextInput,
		TextEditor,
		CloudSaveStatus,
		Btn,
		Dropdown,
		TextInput,
	},
});
