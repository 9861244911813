
import { EventParticipationState } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as participationStateIcons } from "@/assets/participationStateIcons";
import Btn from "../ui/Btn.vue";
import Tooltip from "../ui/Tooltip.vue";
export default defineComponent({
	name: "EventParticipationStateRenderer",
	props: {
		params: {
			type: Object as PropType<{
				value: EventParticipationState;
			}>,
			required: true,
		},
	},
	data() {
		return {
			EventParticipationState,
			participationStateIcons,
		};
	},
	methods: {},
	computed: {},
	//components: { Btn, Tooltip },
});
