
import { SelectableOption } from "@/interfaces";
import { courseIdMixin, scrollMixin } from "@/mixins";
import { userMatchesSearch, User } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import {
	highlightMatchingText,
	setErrorNotification,
	isValidEmailAddress,
} from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { throttle } from "lodash";
import { mapStores } from "pinia";
import Avatar from "../ui/Avatar.vue";
import Btn from "../ui/Btn.vue";
import Combobox from "../ui/Combobox.vue";
import Spinner from "../ui/Spinner.vue";
import TextInput from "../ui/TextInput.vue";
export default defineComponent({
	name: "UserPicker",
	props: {
		modelValue: {
			type: Object as PropType<{
				ids: string[];
				emails: string[];
			}>,
			required: true,
		},
	},
	async created() {
		this.loadingUsers = true;
		await this.throttledGetUsers(null);
	},
	mixins: [courseIdMixin, scrollMixin],
	watch: {
		searchText(newVal) {
			this.loadingUsers = true;
			this.throttledGetUsers(newVal);
		},
	},
	data() {
		return {
			searchText: "",
			loadingUsers: false,
			addingUserIds: [] as string[],
			addingEmails: [] as string[],
			/**
			 * Users in the store may change due to filtered API calls,
			 * so keep a local cache of selected user objects
			 */
			cachedUsers: [] as User[],
			/**
			 * Once an email address has been selected, it's saved into
			 * a local cache so it can show up in later search results
			 * as if it belonged to an actual user
			 */
			cachedEmails: [] as string[],
		};
	},
	methods: {
		onToggleUser(user: Partial<User>) {
			if (user.id) {
				const userId = user.id;
				if (this.modelValue.ids.includes(userId)) {
					this.onRemoveUser({ id: userId });
				} else {
					if (!this.cachedUsers.find(u => u.id == userId)) {
						!this.cachedUsers.push(this.mainStore.getUserById(userId) as User);
					}

					this.addingUserIds.push(userId);
					this.$emit("update:modelValue", {
						emails: this.modelValue.emails,
						ids: [...this.modelValue.ids, userId],
					});
				}
			} else {
				const email = user.email as string;
				if (this.modelValue.emails.includes(email)) {
					this.onRemoveUser({ email });
				} else {
					if (!this.cachedEmails.find(e => e == email)) {
						!this.cachedEmails.push(email);
					}

					this.addingEmails.push(email);
					this.$emit("update:modelValue", {
						emails: [...this.modelValue.emails, email],
						ids: this.modelValue.ids,
					});
				}
			}
		},
		onRemoveUser(user: Partial<User>) {
			if (user.id) {
				this.$emit("update:modelValue", {
					emails: this.modelValue.emails,
					ids: this.modelValue.ids.filter(i => i != user.id),
				});
			} else {
				this.$emit("update:modelValue", {
					emails: this.modelValue.emails.filter(e => e != user.email),
					ids: this.modelValue.ids,
				});
			}
		},
		isUserSelected(user: Partial<User>) {
			if (user.id) {
				return this.modelValue.ids.includes(user.id);
			}
			return this.modelValue.emails.includes(user.email as string);
		},
		async onUserSearch(search: string) {
			this.loadingUsers = true;
			await this.throttledGetUsers(search);
		},
		throttledGetUsers: throttle(async function (this: any, search) {
			try {
				await this.mainStore.getUsersForCourse({
					courseId: this.courseId,
					teachersOnly: false,
					search,
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingUsers = false;
			}
		}, 500),
		highlightMatchingText,
	},
	computed: {
		...mapStores(useMainStore),
		users() {
			return this.mainStore.paginatedUsers.data;
		},
		filteredUsers() {
			return [
				...this.users,
				...this.cachedEmails.map(e => ({ full_name: e, email: e })),
			].filter(u => userMatchesSearch(this.searchText, u as User));
		},
		isSearchTextValidEmail() {
			return isValidEmailAddress(this.searchText);
		},
		addingUsers() {
			return [
				...this.modelValue.ids.map(i => this.cachedUsers.find(u => u.id == i)),
				...this.modelValue.emails.map(e => ({
					full_name: e,
					email: e,
				})),
			];
		},
	},
	components: { Avatar, Spinner, TextInput, Btn },
});
