import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  style: {"filter":"blur(5px)"},
  class: "opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      onYes: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEnroll())),
      disableOk: _ctx.enrolling,
      dismissible: false,
      onNo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel())),
      showDialog: true,
      loading: _ctx.enrolling,
      yesText: _ctx.enrolling ? _ctx.$t('misc.wait') : _ctx.$t('enrollment.enroll'),
      noText: _ctx.$t('dialog.default_cancel_text')
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("enrollment.enrollment")), 1)
      ]),
      body: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("enrollment.do_you_want_to_enroll")) + " ", 1),
        (_ctx.currentCourse.name)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.currentCourse.name), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "course name")),
        _createTextVNode("?")
      ]),
      _: 1
    }, 8, ["disableOk", "loading", "yesText", "noText"])
  ]))
}